import React, { useEffect, useState } from 'react'
import './AgoraDetails.style.css'
import Header from '../../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api/api'
import LoadingPage from '../../../components/LoadingPage/LoadingPage'
import { color } from '../../../hooks/Utils/color'
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdGroups, MdOutlinePublic } from 'react-icons/md'
import { BiLockAlt } from 'react-icons/bi'
import { formatCash } from '../../../hooks/Utils/parsing'
import Opinion from '../../../components/Opinion/Opinion'
import { Spinner } from 'react-bootstrap'
import { TbPlugConnected } from 'react-icons/tb'
import { AiOutlineDisconnect } from 'react-icons/ai'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import ModalCreateOpinion from '../../../components/ModalCreateOpinion/ModalCreateOpinion'



const AgoraDetails = () => {

    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()
    const { ago_id } = useParams()
    const [agoraDetails, setAgoraDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const [agm_status, setAgm_status] = useState(null)
    const [agm_role, setAgm_role] = useState(null)
    const [agm_type, setAgm_type] = useState(null)
    const [agm_moderator, setAgm_moderator] = useState(null)
    const [ago_picture, setAgo_picture] = useState(null)

    const MAX_LENGTH = 150
    const [showDescription, setShowDescription] = useState(false)

    const [opinions, setOpinions] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    const [showModalCreateOpinion, setShowModalCreateOpinion] = useState(false)

    useEffect(() => {
        setPage(1)
        fetchAgoraDetails()
        fetchAgoraOpinions()

    }, [])

    const fetchAgoraDetails = async () => {
        try {
            const request = await api(`api/agoras/${ago_id}`, 'GET', null, accessToken)
            const response = await request.json()
            console.log('Response fetch agora details:', response)
            if (request?.status === 200) {
                if (response?.success) {
                    setAgoraDetails(response)
                    setAgm_status(response?.membership_status?.agm_status)
                    setAgm_role(response?.membership_status?.agm_role)
                    setAgm_type(response?.membership_status?.agm_type)
                    setAgm_moderator(response?.agora?.ago_creator)
                    setAgo_picture(response?.agora?.ago_picture ? response?.agora.ago_picture : null)
                }
            }
            setIsLoading(false)
        } catch (error) {
            fetchAgoraDetails()
            console.log('Error request get agora details:', error.message)
        }
    }


    const fetchAgoraOpinions = async () => {
        try {
            const request = await api(`api/agoras/v2/opinions/${ago_id}?page=${page}`, 'GET', {}, accessToken);
            const response = await request.json()
            // console.log('Get opinion page ' + page + ' :', response)
            if (request?.status === 200) {
                if (response?.success) {

                    if (response?.opinions?.data.length === 0) {
                        setHasMore(false)
                        return
                    }
                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }

                    setOpinions(opinions => [...opinions, ...response?.opinions?.data])
                    setPage(p => p + 1)
                }
            }
            setIsLoading(false)
        } catch (error) {
            fetchAgoraOpinions()
            console.log('Error request get agora details:', error.message)
        }

    }

    const updateOpinion = (data) => {
        const new_opinions = opinions.map(opinion =>
            opinion.sond_id !== data.sond_id ? opinion : data,
        )
        setOpinions(new_opinions)
    }

    const deleteOpinion = (data) => {
        setOpinions(opinions.filter(opinion => opinion.sond_id !== data?.sond_id))
    }



    const joinAnAgora = async () => {
        try {
            console.log('Join...')
            const body = { agm_agora: ago_id }
            const request = await api('api/agoras/join', 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response join an agora:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchAgoraDetails()
                    // sendNotification(response?.moderator, {
                    //     title: `${AuthContextState?.user?.user_surname} ${AuthContextState?.user?.user_name} a demandé à rejoindre votre agora.`,
                    //     body: `Rendez-vous dans la liste des membres de votre agora pour l'accepter.`,
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    // DELETE DEMANDE L'AGORA
    const deleteJoin = async () => {
        try {
            const body = { agm_agora: ago_id }
            const request = await api(
                'api/agoras/members/delete-join',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            console.log('Response delete join', response.message)
            if (request.ok && request.status === 200) {
                fetchAgoraDetails()
            }
        } catch (error) {
            console.log('Delete join an agora errorrr', error.message)
        }
    }




    if (isLoading) {
        return (
            <div style={{ width: '100%', backgroundColor: 'white' }}>
                <Header title={t('group')} goBack={() => navigate(-1)} />
                <div style={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingPage />
                </div>
            </div>
        )
    }



    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }



    const renderButtonParticipate = () => {

        if (agm_status === 0 && agm_type === 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', }}>
                    <button
                        onClick={deleteJoin}
                        className='button'
                        style={{
                            width: '60%',
                            backgroundColor: 'white',
                            color: color.primary,
                            fontWeight: '600',
                            border: `1.8px ${color.primary} solid`
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <AiOutlineDisconnect size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                </div>
            )
        }

        if (agm_status === 0 && agm_type === 1) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', }}>
                    <button
                        onClick={joinAnAgora}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '60%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <TbPlugConnected size={18} color={color.white} />
                        </div>
                        {t('participate')}
                    </button>
                </div>
            )
        }

        if (!agm_status) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', }}>
                    <button
                        onClick={joinAnAgora}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '60%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <TbPlugConnected size={18} color={color.white} />
                        </div>
                        {t('participate')}
                    </button>
                </div>
            )
        }
    }



    return (
        <div className='agora-details'>
            <Header title={agoraDetails?.agora?.ago_title ? agoraDetails?.agora?.ago_title : t('group')} goBack={() => navigate(-1)} />
            <div className='content'>

                <InfiniteScroll
                    dataLength={opinions.length} //This is important field to render the next data
                    next={fetchAgoraOpinions}
                    hasMore={hasMore}
                    loader={renderLoader()}
                    scrollableTarget="content">

                    <div style={{ height: '250px', backgroundColor: color.grayArgent }}>
                        <img src={agoraDetails?.agora?.ago_picture ? agoraDetails?.agora?.ago_picture : require('../../../assets/images/background_opinion.jpg')}
                            alt=''
                            style={{
                                width: '100%',
                                height: '250px',
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                        />
                    </div>

                    <p style={{ color: color.dark, marginLeft: '10%', marginRight: '10%', textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>

                        {agoraDetails?.ago_presentation?.length > MAX_LENGTH && !showDescription ?
                            `${agoraDetails?.agora?.ago_presentation.substring(0, MAX_LENGTH)}...` : agoraDetails?.agora?.ago_presentation
                        }

                        {agoraDetails?.agora?.ago_presentation?.length > MAX_LENGTH &&
                            <button onClick={() => setShowDescription(!showDescription)} style={{ color: color.primary, fontWeight: '600' }}>
                                {showDescription ? 'Read less' : 'Read more'}
                            </button>
                        }
                    </p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20%', marginRight: '20%' }}>

                        <div className='box-info'>
                            <BiLockAlt size={26} color={color.primary} />
                            <span style={{ fontWeight: '600', fontSize: '16px', color: color.primary, marginLeft: '10px' }}>
                                Agora private
                            </span>
                        </div>

                        {agm_status === 1 &&
                            <div onClick={() => navigate(`/agora/${ago_id}/members`, { state: { agm_user_role: agm_role } })} className='box-info' style={{ cursor: 'pointer' }}>
                                <MdGroups size={26} color={color.primary} />
                                <span style={{ fontWeight: '600', fontSize: '16px', color: color.primary, marginLeft: '10px' }}>
                                    {agoraDetails?.agora?.users_count} {agoraDetails?.agora?.users_count > 1 ? t('members') : t('member')}
                                </span>
                            </div>}

                    </div>
                    {renderButtonParticipate()}
                    <div style={{ backgroundColor: '#f1f1f1', margin: '30px', padding: '15px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <MdGroups color={color.secondary} size={28} style={{ marginRight: '5px' }} />
                            <h5 style={{ fontWeight: 'bold', color: color.secondary }}>Audience</h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.agora?.users_count)} Membres
                            </h5>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.agora?.current_opinions_count)} Opinions
                            </h5>
                        </div>


                    </div>

                    <div style={{ backgroundColor: '#f1f1f1', margin: '30px', padding: '15px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <MdOutlinePublic color={color.secondary} size={28} style={{ marginRight: '5px' }} />
                            <h5 style={{ fontWeight: 'bold', color: color.secondary }}>Interactions</h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.agora?.opinions_count)} Opinions publiées
                            </h5>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.votes)} Votes
                            </h5>
                        </div>

                    </div>


                    {agm_status === 1 && opinions.map((opinion, index) => {
                        return (
                            <Opinion
                                key={index.toString()}
                                opinion={opinion}
                                updateOpinion={updateOpinion}
                                deleteOpinion={deleteOpinion}
                                sourceFrom='Agora'
                            />
                        )
                    })}

                </InfiniteScroll>

            </div>

            <div style={{ display: 'flex', justifyContent: 'center', padding: '15px', borderTop: '1px #f1f1f1 solid' }}>
                <ButtonSubmit
                    onClick={() => setShowModalCreateOpinion(true)}
                    value='Publier un poste'
                    style={{
                        color: 'white',
                        backgroundColor: color.primary,
                        width: '60%',
                        fontWeight: '600'
                    }}
                />
            </div>

            <ModalCreateOpinion
                user={authData?.user}
                ago_title={agoraDetails?.agora?.ago_title}
                ago_id={ago_id}
                show={showModalCreateOpinion}
                setShow={setShowModalCreateOpinion}
            />

        </div>
    )
}

export default AgoraDetails