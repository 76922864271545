import React, { useEffect, useState } from 'react'
import './OneToOneMessage.style.css'
import { useNavigate, useParams } from 'react-router-dom';
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks';
import { api } from '../../../api/api';
import RenderMessage from './RenderMessage/RenderMessage';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputEmoji from "react-input-emoji";
import { RiSendPlaneFill, RiUserUnfollowLine } from 'react-icons/ri'
import { RiAttachment2 } from 'react-icons/ri'
import axios from 'axios'
import Constants from '../../../constants/Constants';
import { MdDeleteOutline, MdOutlineKeyboardBackspace } from 'react-icons/md';
import ProfileCard from '../../../components/ProfileCard/ProfileCard';
import { color } from '../../../hooks/Utils/color';
import Compressor from 'compressorjs'
import { useTranslation } from 'react-i18next';
import ModelAlert from '../../../components/ModalAlert/ModelAlert';
import { IoIosCloseCircle } from 'react-icons/io';
import { Modal, Spinner } from 'react-bootstrap';
import LoadingPage from '../../../components/LoadingPage/LoadingPage';
import Header from '../../../components/Header/Header';
import { FaMicrophone } from 'react-icons/fa';
import { AiFillCloseCircle, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { TfiMenu } from 'react-icons/tfi'
import { BiLockAlt } from 'react-icons/bi';
import useReducerAuthAction from '../../../hooks/ReducerHooksAction/useReducerAuthAction';
import { isMobile } from 'react-device-detect';


const OneToOneMessage = () => {

    const controller = new AbortController();
    const signal = controller.signal;
    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
    const { dat: authData } = useAuthReducer()
    const { t } = useTranslation()
    const { user_id } = useParams()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const [userTchat, setUserTchat] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [attachmentsPreview, setAttachmentsPreview] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isBloqued, setIsBloqued] = useState(false);
    const [mute, setMute] = useState(false);
    const [friendMute, setFriendMute] = useState(false);
    const [showAlertVideo, setShowAlertVideo] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const [isVoiceMessage, setIsVoiceMessage] = useState(false)
    const [isLoadingRequest, setIsLoadingRequest] = useState(false)


    // RECORDE AUDIO
    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    let mediaRecorder = null;


    // useEffect(() => {
    //     navigator.mediaDevices.getUserMedia({ audio: true })
    //         .then(stream => {
    //             mediaRecorder = new MediaRecorder(stream);
    //             mediaRecorder.ondataavailable = handleDataAvailable;
    //         })
    //         .catch(err => console.error('getUserMedia error:', err));
    // }, []);

    const handleDataAvailable = (event) => {
        const file = new Blob([event.data], { type: 'audio/mp3' });
        const url = URL.createObjectURL(file);
        setAudioURL(url);

        const data = {
            file,
            width: null,
            height: null,
            duration: null,
            size: file.size,
            type: 'audio',
            thumbnail: null,
            extension: file.type.split("/")[1],
            mime_type: file.type
        }
        setAttachments(attachments => [...attachments, data])
    };

    const startRecording = () => {
        mediaRecorder.start();
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setRecording(false);
    };

    const sendAudio = () => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                console.log('Audio sent successfully');
            }
        };
        xhr.open('POST', 'your-server-url', true);
        xhr.send(audioURL);
    };





    useEffect(() => {
        fetchMessage()
        const timerMessage = setInterval(() => {
            fetchMessage()
        }, 5000);
        return () => {
            clearInterval(timerMessage)
            controller.abort()
        }
    }, [])


    const fetchMessage = async () => {
        try {
            const request = await api(`api/one/${user_id}`, 'GET', null, { ...accessToken, ...signal })
            const response = await request.json()
            // console.log('Response message:', response?.messages)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setIsBloqued(response?.user_blocked)
                    if (response?.user_blocked) {
                        deleteAllMessages()
                    } else {

                        setMessages(response.messages.reverse())
                        setMute(response?.auth_user_muted_conversation)
                        setFriendMute(response?.friend_user_muted_conversation)

                        setUserTchat({
                            user_surname: response?.user?.user_surname,
                            user_name: response?.user?.user_name,
                            user_username: response?.user?.user_username,
                            user_verified: response?.user?.user_verified,
                            user_gold: response?.user?.user_gold,
                            profile: {
                                prof_picture: response?.user?.profile?.prof_picture
                            },
                            connection_status: response?.user?.connection_status,
                            user_badge_business: response?.user?.user_badge_business,
                            user_badge_color: response?.user?.user_badge_color,
                            user_badge_food: response?.user?.user_badge_food,
                            user_badge_goal: response?.user?.user_badge_goal,
                            user_badge_hobby: response?.user?.user_badge_hobby,
                            user_match_value: response?.user?.user_match_value,
                        })

                    }
                    setIsLoading(false)
                }
            }
            setIsLoading(false)
        } catch (error) {
            fetchMessage()
            console.log('Error fetch message:', message)
        }
    }


    const updateLastSeen = async () => {
        try {
            console.log('Updating last seen:', authData?.user?.user_show_last_seen)
            const body = {
                user_show_last_seen: authData?.user?.user_show_last_seen ? false : true
            }
            const request = await api(`api/user/update-show-last-seen`, 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response updating last seen:', response)
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    setShowMenu(false)
                    updateUserDetails()
                }
            }
        } catch (error) {
            setShowMenu(false)
            console.error('Error update last seen:', error);
        }
    }

    const updateUserDetails = async () => {
        try {
            const request = await api('api/get-user', 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Response fetch user:', response?.user.user_show_last_seen)
            if (request.ok && request.status === 200) {
                UPDATE_PROFILE(response?.user)
            }
        } catch (e) {
            updateUserDetails()
            console.warn('Error fetch user info:', e.message)
            throw new Error(e)
        }
    }


    const deleteAllMessages = async () => {
        try {
            const body = {
                user_id: user_id,
            }
            const request = await api(
                'api/one/delete-inbox',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            console.log(response, 'response of delete message ')
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    setShowMenu(false)
                    setMessages([])
                    if (isBloqued) {
                       navigate(-1)
                    }
                }
            }
        } catch (error) {
            setShowMenu(false)
            throw new Error(error.message)
        }
    }

        const blockBuddy = async () => {
            try {
              const body = { user_id }
              const request = await api(
                'api/profile/blocked',
                'POST',
                body,
                accessToken,
              )
              const response = await request.json()
              console.log(response, 'response of BLOC ON ONE TO ONE PROFIL')
              if (request.ok && request.status === 200) {
                if (response.success) {
                 setIsBloqued(true)
                 deleteAllMessages()
                }
              }
            } catch ({ message }) {
              throw new Error(message)
            }
          }
  


    const handleSendMessage = async () => {
        try {
            if (!message && attachments?.length === 0) {
                return
            }
            setIsLoadingRequest(true)
            const formData = new FormData();
            formData.append('receiver_id', user_id);
            formData.append('chat_messages_text', message);
            // formData.append('message_parent_id', messageParent?.id);

            if (attachments[0]?.type == 'document') {
                formData.append('attachments', 1);
                formData.append(`attachment_1`, attachments[0]?.file);
                formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
                formData.append(`attachment_type_1`, attachments[0]?.type);
                formData.append(`attachment_size_1`, attachments[0]?.size);
                formData.append(`attachment_extension_1`, attachments[0]?.name);
            } else if (attachments[0]?.type == 'audio') {
                formData.append('attachments', 1);
                formData.append(`attachment_1`, attachments[0]?.file);
                formData.append(`attachment_type_1`, attachments[0]?.type);
                formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
                formData.append(`attachment_extension_1`, attachments[0]?.extension);
            } else {
                formData.append('attachments', attachments?.length);
                attachments.forEach((attachment, index) => {

                    formData.append(`attachment_${index + 1}`, attachment?.file);
                    formData.append(`attachment_type_${index + 1}`, attachment?.type);
                    formData.append(`attachment_mime_type_${index + 1}`, attachment?.mime_type);

                    if (attachment?.type !== 'audio') {
                        formData.append(`attachment_width_${index + 1}`, attachment?.width);
                        formData.append(`attachment_height_${index + 1}`, attachment?.height);
                        formData.append(`attachment_size_${index + 1}`, attachment?.size);
                    }
                    if (attachment?.type == 'video') {
                        formData.append(`attachment_thumbnail_${index + 1}`, attachment?.thumbnail);
                        formData.append(`attachment_duration_${index + 1}`, attachment?.duration);
                    }
                });
            }

            const request = await axios.post(`${Constants.API_URL}/api/sendMessage`, formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    console.log(Math.round((loaded / total) * 100), '% envoi...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            const response = await request?.data
            console.log('Response send message:', response?.status)
            if (request?.status === 200) {
                if (response?.success) {
                    setMessage('')
                    setAttachments([])
                    setAttachmentsPreview([])
                }
            }
            setIsLoadingRequest(false)
        } catch (error) {
            if (error.message == 'Network Error') {
                handleSendMessage()
            } else {
                handleSendMessage()
            }
            console.error(error.message);
        }
    }



    const handleFileChange = async (e) => {
        if (e.target.files) {

            const file = e.target.files[0]
            const fileUri = window.URL.createObjectURL(file)
            console.log('handleFileChange:', file)

            if (file.type.split("/")[0] === 'image') {


                setAttachmentsPreview(preview => [...preview, fileUri])

                new Compressor(file, {
                    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                    success: async (compressedResult) => {
                        console.log('compressedResult:', compressedResult)
                        const { width, height } = await getImageParams(file)

                        const data = {
                            file: compressedResult,
                            width,
                            height,
                            size: file?.size,
                            type: 'image',
                            extension: file?.type.split("/")[1],
                            mime_type: file?.type
                        }

                        setAttachments(attachments => [...attachments, data])

                    },
                });

            }

            if (file.type.split("/")[0] === 'video') {

                const megabytes = (file.size / 1048576).toFixed(0);
                if (megabytes > 50) {
                    setShowAlertVideo(true)
                } else {
                    // generate video thumbnail
                    const thumbnail = await generateVideoThumbnail(e.target.files[0]);
                    if (thumbnail) {

                        // set video thumbnail
                        const { thumbnailUri, thumbnailFile } = await convertBase64ToFile(thumbnail)

                        setAttachmentsPreview(preview => [...preview, thumbnailUri])

                        const meta = await getVideoMetadata(file)
                        console.log('Video meta:', meta)

                        // load file
                        const data = {
                            file,
                            width: meta?.width,
                            height: meta?.height,
                            duration: meta?.duration,
                            size: file.size,
                            type: 'video',
                            thumbnail: thumbnailFile,
                            extension: file.type.split("/")[1],
                            mime_type: file.type
                        }

                        setAttachments(attachments => [...attachments, data])
                    }
                }
            }

        }
    };




    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.currentTime = 5;
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };



    function getVideoMetadata(file) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                URL.revokeObjectURL(video.src);
                resolve({
                    duration: video.duration,
                    width: video.videoWidth,
                    height: video.videoHeight
                });
            };

            video.onerror = reject;
        });
    }


    const convertBase64ToFile = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const thumbnailFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        const thumbnailUri = URL.createObjectURL(thumbnailFile);
        // setImageUrl(imageUrl);
        // console.log('convertBase64ToFile:', file)
        return { thumbnailUri, thumbnailFile }
    };



    // reading a file to get height and width
    async function getImageParams(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader()

            reader.onload = async (e) => {
                var image = new Image()
                image.src = e.target.result
                await image.decode()

                resolve({ width: image.width, height: image.height })
            }
            reader.readAsDataURL(file)
        })
    }



    const handleDeleteAttachment = (index_preview, url) => {
        setAttachmentsPreview(attachmentsPreview.filter(attach_url => attach_url !== url))
        setAttachments(attachmentsPreview.filter((attach_url, index) => index !== index_preview))
    }



    if (isLoading) {
        return (
            <div style={{ height: '98vh' }}>
                <Header title='Message' />
                <LoadingPage />
            </div>
        )
    }

    return (
        <div className='one-to-one-message'>

            <div className='header'>
                <button onClick={() => navigate(-1)} className='button-back' >
                    <MdOutlineKeyboardBackspace size={32} />
                </button>
                <div>
                    <ProfileCard user={userTchat} />
                </div>
                <button onClick={() => setShowMenu(true)} style={{ width: '30px', height: '30px', marginLeft: 'auto', marginRight: '25px' }}>
                    <TfiMenu size={28} color={color.dark} />
                </button>
            </div>
            <div style={{height: isMobile ?  '70vh' : '80vh'}} className="message-list">
                <InfiniteScroll
                    dataLength={messages.length} //This is important field to render the next data
                    next={fetchMessage}
                    hasMore={false}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="message-list"
                    inverse={true}
                    style={{ display: "flex", flexDirection: "column-reverse" }}>

                    {messages.map((message, index) => {
                        return (
                            <RenderMessage
                                key={index.toString()}
                                user_id={user_id}
                                message={message}
                            />
                        )
                    })}

                </InfiniteScroll>


            </div>

            <div className="message-input-box">

                <div style={{ width: '100%' }}>
                    {attachmentsPreview.length != 0 &&
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', }}>

                            {attachmentsPreview.map((url, index) => {
                                return (
                                    <div>
                                        <button onClick={() => handleDeleteAttachment(index, url)}>
                                            <IoIosCloseCircle size={20} color={color.danger} />
                                        </button>
                                        <img key={index} src={url} width={50} alt='' style={{ marginRight: '15px', marginBottom: '10px', border: `1px ${color.primary} solid`, borderRadius: '3px' }} />
                                    </div>
                                )
                            })}

                        </div>
                    }


                    {isVoiceMessage ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <audio src={audioURL} controls style={{ height: '35px' }} />
                                <button onClick={startRecording} disabled={recording}>Enregistrer</button>
                                <button onClick={stopRecording} disabled={!recording}>Stop</button>
                                <button onClick={handleSendMessage} disabled={!audioURL}>Envoyer</button>
                            </div>
                            <button onClick={() => setIsVoiceMessage(false)}>
                                <AiFillCloseCircle size={30} color={color.primary} />
                            </button>
                        </div>
                        :
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <label
                                htmlFor="choice1"
                                onChange={(e) => handleFileChange(e)}
                                style={{
                                    borderRadius: '3px',
                                    padding: '7px',
                                    cursor: 'pointer'
                                }}>
                                <input accept="image/*, video/*" name="" type="file" id="choice1" hidden />
                                <RiAttachment2 size={30} color={color.primary} style={{ backgroudColor: '#01195f', border: '1px #01195f solid', borderRadius: '30px', padding: '3px' }} />

                            </label>

                            <InputEmoji
                                value={message}
                                onChange={setMessage}
                                // cleanOnEnter
                                keepOpened
                                onEnter={text => handleSendMessage(text)}
                                theme={'light'}
                                placeholder={t('write_your_message')}
                            />

                            {isLoadingRequest ?
                                <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        variant={'primary'}
                                    />
                                </div>
                                // : !message ?
                                //     <button type='submit' onClick={() => setIsVoiceMessage(true)} style={{ width: '30px', height: '30px' }}>
                                //         <FaMicrophone size={30} color='#1880a1' style={{ backgroudColor: '#1880a1', border: '1px #1880a1 solid', borderRadius: '30px', padding: '3px' }} />
                                //     </button>
                                :
                                <button type='submit' onClick={handleSendMessage} style={{ width: '30px', height: '30px' }}>
                                    <RiSendPlaneFill size={30} color='#01195f' style={{ backgroudColor: '#01195f', border: '1px #01195f solid', borderRadius: '30px', padding: '3px' }} />
                                </button>
                            }
                        </div>}

                </div>

            </div>



            {/* Menu */}
            <Modal show={showMenu}>
                <Header title='Menu' type='modal' goBack={() => setShowMenu(false)} />

                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                    <div
                        onClick={updateLastSeen}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        {authData?.user?.user_show_last_seen ? <AiOutlineEyeInvisible size={26} color='#808080' /> : <AiOutlineEye size={26} color='#808080' />}
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {authData?.user?.user_show_last_seen ? t('hide_your_last_seen') : t('show_your_last_seen')}
                        </span>
                    </div>

                    <div
                        onClick={deleteAllMessages}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        <MdDeleteOutline size={26} color='#808080' />
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {t('delete_all_messages')}
                        </span>
                    </div>
                    <div
                        onClick={blockBuddy}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        <RiUserUnfollowLine size={26} color='#808080' />
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {t('block')} {userTchat?.user_surname}  {userTchat?.user_name}
                        </span>
                    </div>

                </div>

            </Modal>


            <ModelAlert
                message={t('video_too_large_please_select_a_video_less_than_50mb')}
                show={showAlertVideo}
                setShow={setShowAlertVideo}
            />

        </div>
    )
}

export default OneToOneMessage