import './translations/IMLocalize'
import AppStack from './routes/AppStack';
import AuthStack from './routes/AuthStack';
import { useAuthReducer } from './hooks/ReducerHooks/ReducerHooks'
import { useEffect } from 'react';


function App() {

  const { data: AuthData } = useAuthReducer()
  localStorage.setItem('language', AuthData.language)

  if (!AuthData?.authorization) {
    return <AuthStack />
  } else {
    return <AppStack />
  }

}

export default App;
