import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillGift, AiOutlineEye } from 'react-icons/ai'
import Moment from 'react-moment'
import SondAttachment from '../SondAttachment/SondAttachment'
import SondCaption from './SondCaption/SondCaption'
import SondOption from './SondOption/SondOption'
import { useNavigate } from 'react-router-dom'
import { color } from '../../../hooks/Utils/color'


const OpinionBody = ({
  opinion,
  viewOpinion,
  setViewOpinion,
  updateOpinion,
  sourceFrom,
}) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [attachments, setAttachments] = useState([])
  const [EventAttachments, setEventAttachments] = useState([])



  useEffect(() => {
    if (opinion?.sond_picture && opinion?.attachments?.length !== 0) {
      const attachment = {
        attach_url: opinion?.sond_picture,
        attach_thumbnail: opinion?.sond_thumbnail,
        attach_type: opinion?.sond_type
      }
      setAttachments([attachment, ...opinion?.attachments])
    }
  }, [opinion?.sond_picture, opinion?.attachments])


  // useEffect(() => {
  //   if (!opinion?.event?.attachments) {
  //     return
  //   }
  //   const event_attach = opinion?.event?.attachments.map(attach=>({
  //       attach_url: attach?.attach_url,
  //       attach_thumbnail: attach?.attach_thumbnail,
  //       attach_type: attach?.attach_type
  //   }))
  //   setEventAttachments(event_attach)
  // }, [opinion?.event])



  const goToViewOpinion = () => {
    if (sourceFrom === 'ViewOpinion') {
      return
    }
    navigate(`/opinion/select/${opinion?.agora?.ago_id ? opinion?.agora?.ago_id : 0}/${opinion?.sond_id}`)
  }




  return (
    <div >

      <SondCaption
        text={opinion?.sond_caption}
        opinion={opinion}
        setViewOpinion={setViewOpinion}
        sourceFrom={sourceFrom}
      />

      <div onClick={goToViewOpinion} className='timestamp-container' style={{ cursor: 'pointer' }}>

        <span style={{ color: color.primary, fontSize: '14px', fontWeight: '400', margin: '0px' }}>

          {opinion?.sond_device_os === 'Android' ? t('opinion_for_android') :
            opinion?.sond_device_os === 'iOS' ? t('opinion_for_ios') :
              opinion?.sond_device_os === 'Web' ? t('opinion_for_web') :
                opinion?.sond_device_os === 'Huawei' ? t('opinion_for_huawei') : t('opinion_for_android')}

        </span>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiFillGift size={16} color={color.primary} />
          <span style={{ color: color.primary, fontSize: '14px', fontWeight: '400', margin: '0px', marginLeft: '3px' }}>
            {opinion?.sond_total_recompense}
          </span>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineEye size={16} color={color.primary} />
          <span style={{ color: color.primary, fontSize: '14px', fontWeight: '400', margin: '0px', marginLeft: '3px' }}>
            {opinion?.sond_views}
          </span>
        </div>

        <p style={{ color: '#808080', fontSize: '13px', margin: '0px' }}>
          <Moment format="DD MMM YYYY HH:MM">
            {opinion?.sond_publishing}
          </Moment>
        </p>

      </div>

      {!!opinion?.options?.length && (
        <div style={{
          display: 'flex', display: 'flex',
          flexWrap: 'wrap', width: '100%'
        }}>
          {opinion?.options?.map(option => {
            return (
              <SondOption
                key={option?.opt_id}
                sond_id={opinion?.sond_id}
                sond_caption={opinion?.sond_caption}
                sond_user={opinion?.sond_user}

                options={opinion?.options}

                opt_id={option?.opt_id}
                opt_image={option?.opt_image}
                opt_type={option?.opt_type}

                opt_size={option?.opt_size}
                opt_extension={option?.opt_extension}
                opt_duration={option?.opt_duration}
                opt_width={option?.opt_width}
                opt_height={option?.opt_height}
                opt_mime_type={option?.opt_mime_type}

                opt_thumbnail={option?.opt_thumbnail}
                opt_libelle={option?.opt_libelle}
                votes_count={option?.votes_count}

                total_count={opinion?.votes_count}
                can_user_vote={opinion?.can_user_vote}
                get_user_vote={opinion?.get_user_vote}


                updateOpinion={updateOpinion}
              // updateVote={updateVote}
              // isOpinionExpired={isOpinionExpired}
              />
            )

          })}
        </div>
      )}


      {(attachments?.length === 1) && (
        <SondAttachment
          attachment={attachments[0]}
          height='auto'
        />
      )}

      {(attachments.length === 2) && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={400}
            />
          </div>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[1]}
              height={400}
            />
          </div>
        </div>
      )}

      {(attachments.length === 3) && (
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={400}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[1]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={200}
            />
          </div>
        </div>
      )}

      {(attachments.length === 4) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={200}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[2]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
          </div>
        </div>
      )}


      {(attachments.length === 5) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={250}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[2]}
              height={166}
            />
            <SondAttachment
              attachment={attachments[3]}
              height={166}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={166}
            />
          </div>
        </div>
      )}

      {(attachments.length === 6) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={200}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[5]}
              height={200}
            />
          </div>
        </div>
      )}


      {(attachments.length === 7) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={266}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={266}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={266}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[5]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[6]}
              height={200}
            />
          </div>
        </div>
      )}



    </div>
  )
}

export default OpinionBody