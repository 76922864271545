import React from 'react'
import './RenderConversation.style.css'
import ProfileCard from '../../../components/ProfileCard/ProfileCard'
import { color } from '../../../hooks/Utils/color'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'


const RenderConversation = ({ user }) => {

    const navigate = useNavigate()


    const goToConversation = () => { 
        navigate(`/one-to-one/${user.user_id}`)
     }




    return (
        <div className='conversation' onClick={goToConversation}>
            
            <ProfileCard
                user={user}
                pictureSize={50}
                sourceFrom={'OneToOneConversation'}
            />

            <div>
                <p style={{
                        backgroundColor: user?.sent_messages_count > 0 ? color.primary : color.grayLight,
                        color: 'white',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        borderRadius: '3px',
                        fontWeight: '600',
                    }}>
                    {user?.sent_messages_count ? user?.sent_messages_count : 0}
                </p>
            </div>

        </div>
    )
}

export default RenderConversation