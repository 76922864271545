import React from 'react'
import Header from '../../../components/Header/Header'
import styles from './EditProfile.module.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AutoCompleteEmployers from '../../../components/AutoCompleteEmployers/AutoCompleteEmployers'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useEffect } from 'react'
import { api } from '../../../api/api'
import { ValidateEmail, ValidatePseudo } from '../../../hooks/Utils/parsing'
import TextInput from '../../../components/TextInput/TextInput'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import { color } from '../../../hooks/Utils/color'
import Select from 'react-select'
import useReducerAuthAction from '../../../hooks/ReducerHooksAction/useReducerAuthAction'


const EditProfile = () => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
  
    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()

    const accessToken = useAccessToken()
    const controller = new AbortController();
    const signal = controller.signal;

    const [prof_title, setProf_title] = useState(user?.profile?.prof_title)
    const [prof_description, setProf_description] = useState(user?.profile?.prof_description)
    const [prof_email, setProf_email] = useState(user?.profile?.prof_email)
    const [prof_site_web, setProf_site_web] = useState(user?.profile?.prof_site_web)
    const [prof_phone, setProf_phone] = useState(user?.profile?.prof_phone)
    const [prof_birthday, setProf_birthday] = useState(user?.profile?.prof_birthday)

    const [user_surname, setUser_surname] = useState(user?.user_surname)
    const [user_name, setUser_name] = useState(user?.user_name)
    const [user_username, setUser_username] = useState(user?.user_username)
    const [user_civility, setUser_civility] = useState(user?.user_civility)
    const [user_ville, setUser_ville] = useState(user?.user_ville)
    const [user_pays, setUser_pays] = useState(user?.user_pays)
    const [user_email, setUser_email] = useState(user?.user_email)

    const [userSupID, setUserSupID] = useState(user?.user_sup_id)
    const [userSupervisor, setUserSupervisor] = useState({
        user_id: user?.upper_hierarchy?.user_id,
        user_name: user?.upper_hierarchy?.user_name,
        user_surname: user?.upper_hierarchy?.user_surname,
    })
    const [userEmployer, setUserEmployer] = useState(user?.user_employer)
    const [userDirection, setUserDirection] = useState(user?.user_direction)
    const [userDepartment, setUserDepartment] = useState(user?.user_department)
    const [userService, setUserService] = useState(user?.user_service)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const [countries, setCountries] = useState([])

    const genders = [
        {
            value: 'M',
            label: 'Male',
        },
        {
            value: 'F',
            label: 'Female',
        },
        {
            value: 'A',
            label: 'Autre',
        },
    ]


    // NAVIGATION HANDLE 
    const goBack = () => navigate(-1)

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const request = await api('api/get-countries', 'GET', null, signal)
                const response = await request.json()
                // console.log('Fetch countries:', response)
                if (request.ok && request.status === 200) {
                    const countries = response.countries.map(c => ({
                        label: c.French_Name,
                        value: c.Sort_Order,
                        key: c.ITU_T_Telephone_Code,
                    }))
                    setCountries(countries)
                }
            } catch (error) {
                fetchCountries()
                console.log('Erreur', error.message)
            }
        }
        fetchCountries()
        return () => {
            controller.abort()
        }
    }, [])




    /* CHECK FORM */
    const checkForm = async (e) => {
        e.preventDefault();
        if (user_surname.trim().length < 3) {
            setError(t('your_surname_must_contain_at_least_4_characters'))
            return
        }
        if (!ValidatePseudo(user_username.trim())) {
            setError(t('your_nickname_must_contain_at_least_4_characters_only_underscore'))
            return
        }
        if (!ValidateEmail(user_email.trim())) {
            setError(t('invalid_email'))
            return
        }
        setError('')
        handleSubmit()
    }


    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const body = {
                prof_title,
                prof_description,
                prof_email,
                prof_site_web,
                user_civility,
                user_surname,
                user_name,
                user_username,
                user_ville,
                prof_phone,
                prof_birthday,
                user_email,
                user_pays: parseInt(user_pays),
                prof_country: parseInt(user_pays),
                prof_city: parseInt(user_ville)
            }

            const request = await api(`api/profiles/${user?.user_id}`, 'PUT', body, accessToken)
            const response = await request.json()
            console.log(response, 'response of profil edit ')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    updateWorkProfile()
                }
            } else {
                if (response?.error === 'The nickname is already taken') {
                    setError(t('the_nickname_is_already_taken'))
                } else {
                    setError('profilEdit:sorry')
                }
            }
            setIsLoading(false)
        } catch (response) {
            setIsLoading(false)
            console.log(response);
        }
    }

    const updateWorkProfile = async () => {
        try {
            const body = {
                prof_title: prof_title,
                user_sup_id: userSupervisor?.user_id,
                user_employer: userEmployer,
                user_direction: userDirection,
                user_department: userDepartment,
                user_service: userService,
            }
            const request = await api(`api/rh/update-profile`, 'PUT', body, accessToken)
            const response = await request.json()
            console.log('Update profile response:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchUser()
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    const fetchUser = async () => {
        try {
            const request = await api('api/get-user', 'GET', {}, { ...accessToken, ...signal })
            const response = await request.json()
            // console.log('Response fetch user:', response?.user)
            if (request.ok && request.status === 200) {
                UPDATE_PROFILE(response?.user)
               goBack()
            } else if (request.status === 401 || request.status === 403) {
                LOGOUT()
            }
        } catch (e) {
            // fetchUser()
            console.warn('Error fetch user info:', e.message)
            throw new Error(e)
        }
    }


    return (
        <div className={styles.screen}>
            <Header title={t('profile_information')} goBack={goBack} />
            <form onSubmit={checkForm}  className={styles.form}>
                {error && <p className={styles.textError}>{error}</p>}

                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('profile_title')}
                    </span>
                    <TextInput
                        value={prof_title}
                        setValue={setProf_title}
                        type='text'
                        placeholder={t('profile_title')}
                        style={{ width: '100%' }}
                    />
                </div>

                <div className={styles.inputContainer}>
                    <AutoCompleteEmployers
                        title={t('supervisor')}
                        type='employees'
                        value={`${userSupervisor?.user_name} ${userSupervisor?.user_surname}`}
                        // setValue={setUserSupID}
                    />
                </div>
                

                <div className={styles.inputContainer}>
                    <AutoCompleteEmployers
                        title={t('compagny_or_entity')}
                        value={userEmployer}
                        setValue={setUserEmployer}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <AutoCompleteEmployers
                        title={t('division')}
                        type='directions'
                        value={userDirection}
                        setValue={setUserDirection}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <AutoCompleteEmployers
                        title={t('department')}
                        type='departments'
                        value={userDepartment}
                        setValue={setUserDepartment}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <AutoCompleteEmployers
                        title={t('unit')}
                        type='services'
                        value={userService}
                        setValue={setUserService}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('profile_description')}
                    </span>
                    <TextInput
                        value={prof_description}
                        setValue={setProf_description}
                        type='text'
                        placeholder={t('profile_description')}
                        style={{ width: '100%' }}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('profile_email')}
                    </span>
                    <TextInput
                        value={prof_email}
                        setValue={setProf_email}
                        type='text'
                        placeholder={t('profile_email')}
                        style={{ width: '100%' }}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('web_site')}
                    </span>
                    <TextInput
                        value={prof_site_web}
                        setValue={setProf_site_web}
                        type='text'
                        placeholder={t('web_site')}
                        style={{ width: '100%' }}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('surname')}
                    </span>
                    <TextInput
                        value={user_surname}
                        setValue={setUser_surname}
                        type='text'
                        placeholder={`${t('surname')}`}
                        style={{ width: '100%' }}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('name')}
                    </span>
                    <TextInput
                        value={user_name}
                        setValue={setUser_name}
                        type='text'
                        placeholder={`${t('name')}`}
                        style={{ width: '100%' }}
                    />
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('nickname')}
                    </span>
                    <TextInput
                        value={user_username}
                        setValue={setUser_username}
                        type='text'
                        placeholder={`${t('nickname')}`}
                        style={{ width: '100%' }}
                    />
                </div>


                <div className={styles.inputCountryContainer}>
                    <div style={{ width: '48%' }}>
                        <span className={styles.titleInput}>
                            {t('country')}
                        </span>
                        <Select
                            placeholder={t('country')}
                            defaultValue={countries.filter(item => item.value === user_pays)}
                            options={countries}
                            onChange={newValue => setUser_pays(newValue.value)}
                        />
                    </div>
                    <div style={{ width: '48%', }}>
                        <span className={styles.titleInput}>
                            {t('city')}
                        </span>
                        <TextInput
                            value={user_ville}
                            setValue={setUser_ville}
                            type='text'
                            placeholder={t('city')}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>


                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('phone_number')}
                    </span>
                    <TextInput
                        value={prof_phone}
                        setValue={setProf_phone}
                        type='phone'
                        placeholder={t('phone_number')}
                        style={{ width: '100%' }}
                    />
                </div>

                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('civility')}
                    </span>
                    <Select
                        placeholder={t('civility')}
                        options={genders}
                        defaultValue={genders.filter(item => item.value == user_civility)}
                        isSearchable={false}
                        onChange={newValue => setUser_civility(newValue.value)}
                    />
                </div>


                <ButtonSubmit
                    value={t('update')}
                    isLoading={isLoading}
                    loadBoostrapColor={'light'}
                    style={{
                        width: '100%',
                        color: 'white',
                        marginBottom: '5%',
                        backgroundColor: color.primary
                    }}
                />

            </form>
        </div>
    )
}

export default EditProfile