import React, { useEffect, useState } from 'react'
import './Transactions.style.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../../components/Header/Header'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'react-bootstrap'
import { api } from '../../../api/api'
import { useAccessToken } from '../../../hooks/ReducerHooks/ReducerHooks'
import { IoWalletOutline } from 'react-icons/io5'
import ic_coins from '../../../assets/coins/coins.png'
import { color } from '../../../hooks/Utils/color'
import { formatCash } from '../../../hooks/Utils/parsing'
import { AiFillLike } from 'react-icons/ai'
import ModelAlert from '../../../components/ModalAlert/ModelAlert'
import TransactionItem from './TransactionItem/TransactionItem'
import InfiniteScroll from 'react-infinite-scroll-component'

const Transactions = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation();
  const { likes } = state;
  const accessToken = useAccessToken()
  const [userBalance, setUserBalance] = useState(0)
  const [coinsExchange, setCoinsExchange] = useState(0.0025)
  const [likesExchange, setLikesExchange] = useState(0.0005)
  const [transactions, setTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    setIsLoading(true)
    fetctTransactions()
    return () => controller.abort()
  }, [])

  const fetctTransactions = async () => {
    try {
      console.log(`Fetch transactions...`)
      const request = await api(`api/transactions?page=${page}`, 'GET', null, { ...accessToken, ...signal })
      const response = await request.json()
      console.log('Response fetch transaction:', response?.data?.data?.length + ' transaction(s)')
      if (request.ok && request.status === 200) {
        setUserBalance(response?.balance)
        if (page == 1 && response?.data?.data?.length == 0) {
          setIsEmpty(true)
          return
        }
        setPage(page => page + 1)
        setTransactions([...transactions, ...response?.data?.data])
        if (!response?.data?.next_page_url) {
          setHasMore(false)
        }
      }
      setIsLoading(false)
    } catch (e) {
      fetctTransactions()
      console.warn(e.message);
    }
  }


  if (isLoading) {
    return (
      <div style={{ backgroundColor: 'white', borderLeft: '1px #f1f1f1 solid', borderRight: '1px #f1f1f1 solid', height: '100%' }}>
        <Header title='Transactions' goBack={() => navigate(-1)} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80%' }}>
          <div style={{ textAlign: 'center' }}>
            <Spinner
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
              variant='secondary'
              style={{
                width: '30px',
                height: '30px'
              }}
            />
            <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
              {t('loading')}
            </h6>
          </div>
        </div>
      </div>
    )
  }

  const renderEmpty = () => {
    return (
      <div style={{ backgroundColor: 'white', borderLeft: '1px #f1f1f1 solid', borderRight: '1px #f1f1f1 solid', height: '450px' }}>
        {/* <Header title='Transactions' goBack={() => navigate(-1)} /> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '70%' }}>
          <div style={{ textAlign: 'center' }}>
            <IoWalletOutline size={58} color='#808080' />
            <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
              {t('no_transactions_available')}
            </h6>
          </div>
        </div>
      </div>
    )
  }

  const renderBalance = () => {
    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', borderBottom: '1px #f1f1f1 solid' }}>
        {/* <h5 style={{ color: color.secondary, fontWeight: 'bold', fontSize: '18px', borderBottom: '1px #f1f1f1 solid' }}>
          {t('balance')}
          </h5> */}

        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', }}>

          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={ic_coins} width="35" alt='' />
              <h5 style={{ fontWeight: '600', marginLeft: '10px' }}>
                {userBalance} coins
              </h5>
            </div>
            <h5 style={{ color: color.success, fontWeight: '600', marginLeft: '10px', marginLeft: '45px', marginTop: '-5px' }}>
              ${(userBalance * coinsExchange).toFixed(2)}
            </h5>
          </div>

          <div style={{ display: '', justifyContent: '' }}>

            <button onClick={() => setShowAlert(true)} style={{ backgroundColor: color.primary, paddingBottom: '3px' }}>
              <span style={{ color: 'white', fontWeight: '500', fontSize: '12px' }}>
                {t('reload')}
              </span>
            </button>

            <button style={{ backgroundColor: '#808080', paddingBottom: '3px' }}>
              <span style={{ color: 'white', fontWeight: '500', fontSize: '12px' }}>
                {t('collect')}
              </span>
            </button>

          </div>

        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', }}>

          <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AiFillLike size={35} color={'#1880a1'} />
              <h5 style={{ fontWeight: '600', marginLeft: '10px', marginTop: '5px' }}>
                {formatCash(likes)} Likes
              </h5>
            </div>
            <h5 style={{ color: color.success, fontWeight: '600', marginLeft: '10px', marginLeft: '45px', }}>
              ${(likes * likesExchange).toFixed(2)}
            </h5>
          </div>

          <div style={{ display: '', justifyContent: '' }}>

            <button style={{ backgroundColor: '#808080', paddingBottom: '3px' }}>
              <span style={{ color: 'white', fontWeight: '500', fontSize: '12px' }}>
                {t('collect')}
              </span>
            </button>
          </div>

        </div>
        <h5 style={{ color: color.secondary, fontWeight: 'bold', fontSize: '18px' }}>Transactions</h5>

      </div>
    )
  }


  const renderLoader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
        <div style={{ textAlign: 'center' }}>
          <Spinner
            size="sm"
            role="status"
            aria-hidden="true"
            animation="border"
            variant='secondary'
            style={{
              width: '25px',
              height: '25px'
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='transactions'>
      <Header title='Transactions' goBack={() => navigate(-1)} />
      <div>

        {transactions.length == 0 ?
          <div>
            {renderBalance()}
            {renderEmpty()}
          </div>
          :
          <div className='infinite-scroll'>
            <InfiniteScroll
              dataLength={transactions.length}
              next={fetctTransactions}
              hasMore={hasMore}
              loader={renderLoader()}
              scrollableTarget='infinite-scroll'
              height={'92vh'}>
              {transactions.map((item, index) => {
                return (
                  <>
                    {index == 0 && renderBalance()}
                    <TransactionItem
                      key={index.toString()}
                      user={item?.user}
                      transac_user_id={item?.transac_user_id}
                      transac_receiver_id={item?.transac_receiver_id}
                      transac_id={item?.transac_id}
                      transac_external_id={item?.transac_external_id}
                      transac_key={item?.transac_key}
                      transac_merchand_order_id={item?.transac_merchand_order_id}
                      transac_pricing_id={item?.transac_pricing_id}
                      transac_model_id={item?.transac_model_id}
                      transac_method_id={item?.transac_method_id}
                      transac_amount={item?.transac_amount}
                      transac_coins={item?.transac_coins}
                      transac_method_number={item?.transac_method_number}
                      transac_type={item?.transac_type}
                      transac_method_fees={item?.transac_method_fees}
                      transac_payesha_fees={item?.transac_payesha_fees}
                      transac_model_type={item?.transac_model_type}
                      transac_last_balance={item?.transac_last_balance}
                      transac_new_balance={item?.transac_new_balance}
                      transac_status={item?.transac_status}
                      method={item?.method}
                      pricing={item?.pricing}
                      transac_date={item?.transac_date}
                      transac_details={item}
                      receiver={item?.receiver}
                    />
                  </>
                )
              })}
            </InfiniteScroll>
          </div>
        }
      </div>
      <ModelAlert
        message={t('coming_soon')}
        show={showAlert}
        setShow={setShowAlert}
      />
    </div>
  )
}

export default Transactions