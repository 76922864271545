import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import './ViralFeed.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { api } from '../../../api/api'
import Opinion from '../../Opinion/Opinion'

const ViralFeed = () => {

    // STATE
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        setIsLoading(true)
        try {
            const request = await api(`api/feed?orderBy=viral&page=${page}`, 'GET', {})
            const response = await request.json()
            // console.log('Response fetch opinions:', response)
            if (request.status === 200) {
                if (response.success) {
                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    if (response?.opinions?.data?.lengh === 0) {
                        setIsEmpty(true)
                        return
                    }

                    // USE DATA
                    setPage(page => page + 1)
                    setOpinions(opinions => [...opinions, ...response?.opinions?.data])
                } else {

                }
            } else {

            }
            setIsLoading(false)
        } catch (error) {
            fetchOpinions()
            setIsLoading(false)
            console.warn(error.message);
        }
    };


    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }


    return (
        <div id="viralScrollableDiv" className='viral-feed'>
            <InfiniteScroll
                dataLength={opinions.length}
                next={fetchOpinions}
                hasMore={hasMore}
                scrollableTarget="viralScrollableDiv"
                loader={renderLoader()}
                style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}>

                {/* RENDER ITEM */}
                {opinions.map((item, index) => {
                    return (
                        <Opinion
                            key={index}
                        opinion={item}
                            item={item}
                            index={index}
                            sourceFrom={'Feed'}
                        />
                    )
                })}

            </InfiniteScroll>
        </div>
    )
}

export default ViralFeed