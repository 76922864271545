import React, { useCallback, useEffect, useState } from 'react'
import './RenderMessage.style.css'
import { useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../../hooks/Utils/color'
import moment from 'moment';
import 'moment/locale/fr';
import { ReactTinyLink } from 'react-tiny-link';
import { FaRegPlayCircle } from 'react-icons/fa';
import Video from './Video/Video';
import Image from './Image/Image';
import Audio from './Audio/Audio';

const RenderMessage = ({
    user_id,
    message
}) => {

    const { data: authData } = useAuthReducer()
    const isMyMessage = message?.sender_id === authData?.user?.user_id && message?.receiver_id !== user_id
    const timeAgo = moment(message.chat_messages_datetime).locale(authData?.language).fromNow();
    const [link, setLink] = useState(null)




    useEffect(() => {

        const link = findLinkInText(message?.chat_messages_text);
        setLink(link)

    }, [message?.chat_messages_text])

    const findLinkInText = (text) => {
        if (!text) {
            return
        }
        const linkRegex = /(https?:\/\/[^\s]+)/;
        const linkMatch = text.match(linkRegex);
        return linkMatch ? linkMatch[0] : null;
    }



    const RenderAttachment = (attachment) => {
        if (attachment.attach_type === 'audio') {
            return (
                <Audio attachment={attachment} />
            )
        }
        if (attachment.attach_type === 'image') {
            return (
                <Image attachment={attachment} />
            )
        }
        if (attachment.attach_type === 'video') {
            return (
                <Video attachment={attachment} />
            )
        }
    }

    return (
        <div className='message-box'>

            <div className={isMyMessage ? 'my-message' : 'message'}>

                {message?.attachments.length !== 0 &&
                    message?.attachments.map((attachment) => RenderAttachment(attachment))
                }

                {message?.message_parent?.chat_messages_text &&
                    <p className='text' style={{ fontSize: '13px', color: '#f1f1f1', borderBottom: '1px #f1f1f1 solid' }}>
                        {message?.message_parent?.chat_messages_text}
                    </p>
                }

                {message?.chat_messages_text &&
                    <p className='text'>
                        {message?.chat_messages_text}
                    </p>
                }

                {link &&
                    <ReactTinyLink
                        cardSize="small"
                        showGraphic={true}
                        maxLine={2}
                        minLine={1}
                        url={link}
                    />
                }
                <p className='timestamp'> {timeAgo} </p>
            </div>
        </div>
    )
}

export default RenderMessage