import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Feed.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import RandomFeed from './RandomFeed/RandomFeed'
import RecentFeed from './RecentFeed/RecentFeed'
import ViralFeed from './ViralFeed/ViralFeed'
import { color } from '../../hooks/Utils/color';

const Feed = () => {

    // STATE
    const { t } = useTranslation()
    const [tabView, setTabView] = useState(1)

    return (
        <div className='feed'>

            <div className='header'>

                <button onClick={() => setTabView(1)} className='tab-head' style={{ borderBottom: tabView == 1 ? `3px ${color.primary} solid` : 'none' }}>
                    <h6 style={{ color: tabView === 1 ? color.primary : '#474747' }}>
                        {t('random')}
                    </h6>
                </button>

                <button onClick={() => setTabView(2)} className='tab-head' style={{ borderBottom: tabView == 2 ? `3px ${color.primary} solid` : 'none' }}>
                    <h6 style={{ color: tabView === 2 ? color.primary : '#474747' }}>
                    {t('recent')}
                    </h6>
                </button>

                <button onClick={() => setTabView(3)} className='tab-head' style={{ borderBottom: tabView == 3 ? `3px ${color.primary} solid` : 'none' }}>
                    <h6 style={{ color: tabView === 3 ? color.primary : '#474747' }}>
                    {t('viral')}
                    </h6>
                </button>

            </div>

            <div style={{ display: tabView !== 1 ? 'none' : '', paddingTop: '8.5%'}}>
                <RandomFeed />
            </div>

            <div style={{ display: tabView !== 2 ? 'none' : '', paddingTop: '8.5%'}}>
                <RecentFeed />
            </div>

            <div style={{ display: tabView !== 3 ? 'none' : '', paddingTop: '8.5%'}}>
                <ViralFeed />
            </div>

        </div>
    )
}

export default Feed