import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import './RandomFeed.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { api } from '../../../api/api'
import Opinion from '../../Opinion/Opinion'
import Spinner from 'react-bootstrap/Spinner'



const RandomFeed = () => {

    // STATE
    const { t } = useTranslation()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(true)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        if (isRefresh) {
            setIsLoading(true)
        }
        try {
            const request = await api(`api/feed?orderBy=random&page=${page}`, 'GET', {})
            const response = await request.json()
            console.log('Response fetch opinions random:', response?.opinions?.current_page)
            if (request.status === 200) {
                if (response.success) {
                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    if (response?.opinions?.data?.lengh === 0) {
                        setIsEmpty(true)
                        return
                    }

                    // DATA
                    setPage(item => item + 1)
                    setOpinions(opinions => [...opinions, ...response?.opinions?.data])
                } else {

                }
            } else {

            }
            setIsRefresh(false)
            setIsLoading(false)
        } catch (error) {
            fetchOpinions()
            setIsLoading(false)
            console.warn(error.message);
        }
    };


    // if (!isLoading) {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
    //             <div style={{ textAlign: 'center' }}>
    //                 <Spinner
    //                     size="sm"
    //                     role="status"
    //                     aria-hidden="true"
    //                     animation="border"
    //                     variant='secondary'
    //                     style={{
    //                         width: '30px',
    //                         height: '30px'
    //                     }}
    //                 />
    //                 <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
    //                     {t('loading_opinion')}
    //                 </h6>
    //             </div>
    //         </div>
    //     )
    // }


    // const renderLoader = () => {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
    //             <div style={{ textAlign: 'center' }}>
    //                 <Spinner
    //                     size="sm"
    //                     role="status"
    //                     aria-hidden="true"
    //                     animation="border"
    //                     variant='secondary'
    //                     style={{
    //                         width: '25px',
    //                         height: '25px'
    //                     }}
    //                 />
    //                 <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
    //                     {t('loading_opinion')}
    //                 </h6>
    //             </div>
    //         </div>
    //     )
    // }



    return (
        <div id="randomScrollableDiv" className='random-feed'>
            {opinions.length === 0 ?
                <div>
                    <h3 style={{ marginTop: '50%', textAlign: 'center' }}>
                        Private
                    </h3>
                </div>
                :
                <InfiniteScroll
                    dataLength={opinions.length}
                    next={fetchOpinions}
                    hasMore={hasMore}
                    loader={
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                            <Spinner animation="border" variant="dark" />
                        </div>
                    }
                    scrollableTarget="randomScrollableDiv"
                    style={{
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}>
                    {/* RENDER ITEM */}
                    {opinions.map((item, index) => {
                        return (
                            <Opinion
                                key={index}
                                item={item}
                                opinion={item}
                                index={index}
                                sourceFrom={'Feed'}
                            />
                        )
                    })}
                </InfiniteScroll>
            }
        </div>
    )
}

export default RandomFeed