import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import Member from './Member/Member'
import LoadingPage from '../../../components/LoadingPage/LoadingPage'
import { color } from '../../../hooks/Utils/color'

const AgoraMembers = () => {

    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()
    const { ago_id } = useParams()
    const { state } = useLocation();
    const { agm_user_role } = state
    const [members, setMembers] = useState([])
    const [memberSearch, setMemberSearch] = useState([])
    const [requestSearch, setRequestSearch] = useState([])
    const [requests, setRequests] = useState([])
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        fetchAgoraMembers()
    }, [])

    const fetchAgoraMembers = async () => {
        try {
            const request = await api(`api/agoras/members/${ago_id}`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Response fetch agora members:', response?.members)
            if (request.ok && request.status === 200) {
                if (response.success) {

                    setMembers(response.members)
                    setMemberSearch(response.members)

                    const allRequests = response.requests
                    const requestsOfUserWhoHaveRequestToParticipate = allRequests.filter(request =>
                        request?.pivot?.agm_status === 0 &&
                        request?.pivot?.agm_type === 0,
                    )
                    setRequests(requestsOfUserWhoHaveRequestToParticipate)
                    setRequestSearch(requestsOfUserWhoHaveRequestToParticipate)
                }
            }
            setIsLoading(false)
        } catch (error) {
            fetchAgoraMembers()
            console.log('Error fetch agora members:', error.message)
        }
    }

    useEffect(() => {
        if (search) {
            const newData = memberSearch.filter(
                user =>
                    user?.user_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    user?.user_surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    user?.user_username?.toLowerCase()?.includes(search?.toLowerCase()),
            )
            setMembers(newData)
        } else {
            setMembers(memberSearch)
        }
    }, [search])


    if (isLoading) {
        return (
            <div style={{width: '100%', backgroundColor: 'white' }}>
                <Header title='Membres' goBack={() => navigate(-1)} />
                <div style={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingPage />
                </div>
            </div>
        )
    }


    return (
        <div style={{ height: '100vh', backgroundColor: 'white' }}>
            <Header title='Members' goBack={() => navigate(-1)} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <input
                    type='search'
                    placeholder={`${t('search')}...`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='search-input'
                />
            </div>
            <div style={{ height: '95vh', overflowY: 'auto' }}>
                <InfiniteScroll
                    dataLength={members.length + requests.length}
                    next={() => null}
                    hasMore={false}>


                    {requests.length !== 0 &&
                        <h6 style={{ fontWeight: 'bold', padding: '8px', color: color.white, backgroundColor: color.primary }}>
                            Demande de participation ({requests.length})
                        </h6>
                    }
                    {requests.map((member, index) => {
                        return (
                            <Member
                                key={index.toString()}
                                ago_id={ago_id}
                                member={member}
                                agm_user_role={agm_user_role}
                                agm_member_user_id={member?.pivot?.agm_user}
                                agm_member_role={member?.pivot?.agm_role}
                                agm_member_status={member?.pivot?.agm_status}
                                refresh={fetchAgoraMembers}
                            />
                        )
                    })}

                    {members.length !== 0 &&
                        <h6 style={{ fontWeight: 'bold', padding: '8px', color: color.white, backgroundColor: color.primary, marginTop: "20px" }}>
                            Liste des membres ({members.length})
                        </h6>
                    }
                    {members.map((member, index) => {
                        return (
                            <Member
                                key={index.toString()}
                                ago_id={ago_id}
                                member={member}
                                agm_user_role={agm_user_role}
                                agm_member_user_id={member?.pivot?.agm_user}
                                agm_member_role={member?.pivot?.agm_role}
                                agm_member_status={member?.pivot?.agm_status}
                                refresh={fetchAgoraMembers}
                            />
                        )
                    })}

                </InfiniteScroll>
            </div>

        </div>
    )
}

export default AgoraMembers