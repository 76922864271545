import React, { useState } from 'react'
import './ChangePassword.style.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import Header from '../../../components/Header/Header'
import TextInput from '../../../components/TextInput/TextInput'
import { color } from '../../../hooks/Utils/color'
import { ValidateConfirmPassword } from '../../../hooks/Utils/parsing'
import { api } from '../../../api/api'
import { useAccessToken } from '../../../hooks/ReducerHooks/ReducerHooks'

const ChangePassword = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const checkForm = (e) => {
        e.preventDefault();
        if (newPassword.length < 8) {
            setError(t('invalid_password'))
            return
        }
        if (!ValidateConfirmPassword(newPassword, confirmNewPassword)) {
            setError(t('passwords_do_not_match'))
            return
        }
        setError('')
        handleChangePassword()
    }


    const handleChangePassword = async () => {
        try {
            setIsLoading(true)
            const body = {
                password: password,
                new_password: newPassword,
                new_password_confirmation: confirmNewPassword
            }
            try {
                const request = await api('api/users/update-password', 'POST', body, accessToken)
                const response = await request.json()
                console.log('Response change password:', response)
                if (request.ok && request.status === 200) {
                    setMessage(t('your_password_has_been_updated'))
                    setError('')
                    setPassword('')
                    setNewPassword('')
                    setConfirmNewPassword('')
                } else {
                    setMessage('')
                    setError(t('your_current_password_is_incorrect'))
                }
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                setMessage('')
                setError(t('no_internet_connection'))
                console.error('Error change password:', error);
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div className='change-password'>

            <Header title='Change password' goBack={() => navigate(-1)} />

            <div style={{ paddingTop: '5px', paddingLeft: '15%', paddingRight: '15%', backgroundColor: 'white', height: '100vh' }}>
                <h6 style={{ textAlign: 'center', color: '#808080', marginTop: '10px' }}>
                    {t('update_your_login_settings_associated_with_your_account')}
                </h6>
                <h5 style={{ fontSize: '16px', textAlign: 'center', color: color.success, marginTop: '10px', marginBottom: '10px' }}>
                    {message}
                </h5>
                <h6 style={{ fontSize: '14px', textAlign: 'center', color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                    {error}
                </h6>

                <form onSubmit={checkForm} >

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                    {t('current_password')}
                    </span>
                    <TextInput
                        value={password}
                        setValue={setPassword}
                        type='password'
                        placeholder={t('current_password')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                    {t('new_password')}
                    </span>
                    <TextInput
                        value={newPassword}
                        setValue={setNewPassword}
                        type='password'
                        placeholder={t('new_password')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                    {t('confirmation_of_new_password')}
                    </span>
                    <TextInput
                        value={confirmNewPassword}
                        setValue={setConfirmNewPassword}
                        type='password'
                        placeholder={t('confirmation_of_new_password')}
                        style={{ width: '100%', marginBottom: '5%' }}
                    />

                    <ButtonSubmit
                        value={t('update')}
                        isLoading={isLoading}
                        loadBoostrapColor={'light'}
                        style={{
                            width: '100%',
                            color: 'white',
                            marginBottom: '5%',
                            backgroundColor: '#1880a1'
                        }}
                    />

                </form>

            </div>

        </div>
    )
}

export default ChangePassword