import React, { useEffect, useState } from 'react'
import './OneToOne.style.css'
import logo from '../../assets/images/logo.png';
import { useOneToOneReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import RenderConversation from './RenderConversation/RenderConversation';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import TopBar from '../../components/Topbar/Topbar';

const OneToOne = () => {

  const { t } = useTranslation()
  const { data: oneToOneData } = useOneToOneReducer()
  const [conversations, setConversation] = useState([])
  const [search, setSearch] = useState('')


  useEffect(() => {
    if (search) {
      const newData = oneToOneData.buddies.filter(
        user =>
          user?.user_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          user?.user_surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
          user?.user_username?.toLowerCase()?.includes(search?.toLowerCase()),
      )
      setConversation(newData)
    } else {
      setConversation(oneToOneData.conversations)
    }
  }, [search])


  return (
    <div className='onetoone'>
      {isMobile ?
        <TopBar />
        :
        <div className='header'>
          <h5 className='title'>{t('inbox')}</h5>
        </div>
      }
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <input
          type='search'
          placeholder={`${t('search')}...`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='search-input'
        />
      </div>
      <div onClick={() => null} style={{ height: '89vh', paddingTop: '10px', paddingBottom: '8%', overflowY: 'auto' }}>

        {conversations.map((user, index) => {
          return (
            <RenderConversation
              key={index.toString()}
              user={user}
            />
          )
        })}

      </div>

    </div>
  )
}

export default OneToOne