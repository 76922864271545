import React, { useEffect, useMemo, useState } from 'react'
import styles from './RequestSent.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { useNavigate } from 'react-router-dom'
import Header from '../../../../components/Header/Header'
import { api } from '../../../../api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import RenderRequest from './RenderRequest/RenderRequest'
import Spinner from 'react-bootstrap/Spinner'


const RequestSent = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)


    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)

    useEffect(() => {
        const fetchRequest = async () => {
            try {
                setIsLoading(true)
                const request = await api(`api/rh/hr_requests?page=1`, 'GET', {}, accessToken)
                const response = await request.json()
                console.log('Fetch request sent response:', response)
                setIsLoading(false)
                if (request?.status === 200) {
                    if (response?.success) {
                        if (response?.data?.next_page_url) {
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }
                        if (response?.data?.data?.length === 0) {
                            setIsEmpty(true)
                            return
                        }
                        setData(response?.data?.data)
                        setPage(2)
                    }
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Fetch request sent error:', error);
            }
        }
        fetchRequest()
    }, [])


    const fetchMoreRequest = async () => {
        try {
            setIsLoading(true)
            const request = await api(`api/rh/hr_requests?page=${page}`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Fetch request sent response:', response)
            setIsLoading(false)
            if (request?.status === 200) {
                if (response?.success) {
                    if (response?.data?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    setData([...data, ...response?.data?.data])
                    setPage(page + 1)
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Fetch request sent error:', error);
        }
    }


    return (
        <div className={styles.screen}>
            <Header title={t('requestSent')} goBack={goBack} />
            <div className={styles.container}>
                <InfiniteScroll
                    dataLength={data?.length}
                    next={fetchMoreRequest}
                    hasMore={hasMore}
                    loader={
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                            <Spinner animation="border" variant="dark" />
                        </div>
                    }
                    scrollableTarget='container'
                    height={'92vh'}>
                    {data.map((item, index) => (
                        <div key={index}>
                            <RenderRequest
                                request={item}
                                document={item?.document}
                                type={item?.type}
                            />
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default RequestSent