const en = {
    translation: {
        /*============= LOGIN */
        download_myopinion: "Téléchargez Myopinion",
        with_myopinion_share_and_stay: "Transform your intranet into a dynamic and collaborative Social Hub for internal communication.",
        login_now_to_interact_with_thousands_of_people: "Login now to interact with millions of people.",
        connect_with: "Connect with",
        login_with_gmail: "Login with Gmail",
        login_with_facebook: "Login with facebook",
        login_with_apple: "Login with Apple",
        create_an_account: "Create an account",
        continue: "Continue",
        reset: "Reset",
        or: "or",
        login: "Login",
        email: "Email",
        password: "Password",
        new_password: "New password",
        confirm_password: "Confirm password",
        forgot_password: "Forgot your password",
        forgot_your_password: "Forgot your password ?",
        please_enter_your_account_email_below_to_reset_it: "Please enter your account email below to reset it.",
        terms_and_conditions: "Terms and Conditions",
        privacy_policy: "Privacy Policy",
        french_france: "French (France)",
        english_us: "English (US)",
        link_terms_of_use: "https://payesha.com/legal/en/conditions-generales.html",
        link_privacy_policy: "https://payesha.com/legal/en/conditions-generales.html",
        incorrect_email_address_or_password: "Incorrect email address or password.",
        an_error_occurred_please_try_again_later: "An error occurred, please try again later.",
        trend_for_you: "Trend for you",
        enter_your_new_password: "Enter your new password",

        /*================ REGISTER */
        sign_up: "Sign up",
        you_do_not_have_an_account: "You do not have an account ? Create your account, it takes less than a minute",
        name: "Name",
        surname: "Surname",
        nickname: "Nickname",
        civility: "Civility",
        phone_number: "Phone number",
        city: "City",
        country: "Country",
        register: "Register",
        confirm: "Confirm",
        confirmation_code: "Confirmation code",
        account_verification: "Account verification",
        a_6_digit_code_has_just_been_sent: "A 6-digit code has just been sent to your email address",
        if_you_dont_see_the_email_look_in_your_spam: "If you don't see the email, look in your spam.",
        password_reset: "Password reset",

        /*============ ERROR INPUT */
        your_surname_must_contain_at_least_4_characters: "Your surname must contain at least 4 characters.",
        your_nickname_must_contain_at_least_4_characters_only_underscore: "Your nickname must contain at least 4 characters, the underscore (_) is allowed.",
        invalid_confirmation_code: "Invalid confirmation code.",
        invalid_confirmation_code_at_least_6_characters: "Invalid confirmation code, the confirmation code must contain at least 6 characters.",
        invalid_email: "Invalid email.",
        invalid_password: "Invalid password, your password must contain at least 8 characters.",
        passwords_do_not_match: 'passwords do not match.',

        /*============== ERROR REQUEST */
        the_email_is_already_taken: "The email is already taken",
        the_email_is_already_taken_or_you_are_not_authorized: "The email is already taken or you are not authorized to use this app.",
        no_internet_connection: "No internet connection, check your internet connection and try again.",
        this_email_does_not_have_an_account: "This Email does not have an account.",

        /*============ OPINIONS */
        audience: "Audience",
        public: "Public",
        recent: 'Recent',
        loading_recent: 'Loading Recent...',
        random: 'Random',
        forYou: 'For you',
        loading_random: 'Loading Random...',
        loading_for_you: 'Loading for you...',
        viral: 'Viral',
        loading: 'Loading...',
        loading_viral: 'Loading Viral...',
        loading_audience: 'Loading contacts...',
        loading_opinion: 'Loading opinions...',
        loading_profile: 'Loading profile...',

        //========= PROFILE
        profile: 'Profile',
        setting: 'Setting',
        language: 'Language',
        account: 'Account',
        update: 'Update',
        update_your_login_settings_associated_with_your_account: 'Update your connection settings associated with your account.',

        buddy: "Contact",
        buddies: "Contacts",
        become_buddy: 'Connect',
        you_are_buddy: 'Connect',

        publish: 'Publish',
        inbox: 'Inbox',
        groups: 'Groups',
        group: 'Group',
        contact: 'Contact',
        contacts: 'Contacts',
        search: 'Search',
        fan: 'Follower',
        fans: 'Followers',
        fan_of: 'Following',
        become_fan: 'Follow',
        you_are_fan: 'Following',
        approve: 'Accept',
        approveRequest: 'Accept request',
        Received: 'Received',
        request: 'Request',
        sent: 'Sent',
        cancel: ' Cancel',
        block: 'Block',
        blocked: 'Blocked',
        unlock: 'Unlock',
        no_buddy: 'No buddy',
        network: 'Network',

        follower: 'Follower',
        followers: 'Followers',
        following: 'Following',

        like: 'Like',
        likes: 'Likes',
        post: 'Post',
        posts: 'Posts',
        no_user: 'No contact',

        //========== BADGE

        /* Goal */
        goal: 'Life goal',
        love: 'Love',
        god: 'God',
        money: 'Money',
        power: 'Power',
        fame: 'Fame',
        family: 'Family',
        humanitary: 'Humanitarian',
        patriot: 'Patriot',
        peace: 'Peace',
        freedom: 'Freedom',
        saveThePlanet: 'Save The Planet',

        /* Business */
        business: 'Business',
        technology: 'Technology',
        finance: 'Finance',
        arts: 'Arts',
        health: 'Health',
        foodIndustry: 'Food industry',
        education: 'Education',
        law: 'Law',
        restaurant: 'Catering',
        communication: 'Communication',
        services: 'Services',
        proAthlete: 'Pro-Athlete',
        agriculture: 'Agriculture',


        /* Hooby */
        hobby: 'Hobby',
        travel: 'Travel',
        sport: 'Sport',
        cinema: 'Cinema',
        nightLife: 'Dance night',
        communityLife: 'Community life',
        art: 'Art',
        gardening: 'Gardening',
        hike: 'Hiking',
        hunting: 'Hunting',
        fishing: 'Fishing',
        music: 'Music',
        videoGame: 'Video game',


        /* Food */
        food: 'Food',
        vegetarian: 'Vegetarian',
        meat: 'Meat',
        fish: 'Fish',
        bread: 'Bread',
        cake: 'Cake',
        pasta: 'Pasta',
        soup: 'Soup',
        seaFood: 'Sea food',
        cereals: 'Cereals',
        dairy: 'Dairy',
        rice: 'Rice',
        wildMeat: 'Wild meat',

        /* Color */
        color: 'Color',
        red: 'Red',
        blue: 'Blue',
        green: 'Green',
        yellow: 'Yellow',
        orange: 'Orange',
        black: 'Black',
        white: 'White',
        purple: 'Purple',
        pink: 'Pink',
        brown: 'Brown',
        grey: 'Gray',
        cyan: 'Cyan',
        beige: 'Beige',

        select_at_least_one_badge_per_category: 'Select at least one badge per category.',
        you_cannot_modify_your_badges: 'You cannot update your badges because you last updated it less than 30 days ago.',

        edit_profile: 'Edit profile',
        profile_information: 'Profile information',
        edit: 'Edit',

        profile_title: 'Profile title',
        profile_description: 'Profile description',
        profile_email: 'Profile Email',
        web_site: 'Web site',

        supervisor: 'Supervisor',
        compagny_or_entity: 'Compagny or Entity',
        division: 'Division',
        department: 'Department',
        unit: 'Unit',

        subscribe_to_email_notification: 'Unsubscribe to email notification',
        yes: 'Yes',
        no: 'No',
        security_and_password: 'Security and password',
        balance: 'Balance',
        subscriptions: 'Subscriptions',

        current_password: 'Current password',
        confirmation_of_new_password: 'Confirmation of new password',
        your_current_password_is_incorrect: 'Your current password is incorrect.',
        your_password_has_been_updated: 'Your password has been updated.',

        coming_soon: 'Coming soon',
        reload: 'Reload',
        collect: 'Collect',
        no_transactions_available: 'No transactions available',

        buying_coins: 'Digital Gifts',
        verified_account: 'Verified account',
        premium_account: 'Premium account',
        sponsored_opinion: 'Sponsored opinion',
        send_gift_to: 'Send gift to',
        gift_received_from: 'Gift received from',
        monthly_subscription: 'Monthly Subscription',
        annual_subscription: 'Annual subscription',

        // create opinion
        create_opinion: 'Create post',
        activate_vote: 'Activate vote',
        enter_your_opinion: 'Enter your opinion',
        publishing_in_progress: 'Publishing in progress...',
        add_to_your_opinion: 'Add to your post',
        publish: 'Publish',
        choice: 'Choice',
        at: 'at',
        mandatory: 'mandatory',
        you_must_add_a_description_to_your_opinion: 'You must add a description to your opinion.',
        the_first_two_choices_are_mandatory: 'The first two choices are mandatory.',
        video_too_large_please_select_a_video_less_than_50mb: 'Video too large, please select a video less than 50mb.',

        mute: 'Mute',
        unmute: 'Unmute',
        report: 'Report',
        download: 'Download',
        delete: 'Delete',
        available: 'Available',
        unavailable: 'Unavailable',
        sorry: 'Sorry',

        // REPORT
        opinionTitle: 'opinion',
        commentTitle: 'comment',
        conversationTitle: 'conversation',
        reportOpinion: {
            sexualContent: 'Sexual content',
            violent: 'Violent or repulsive content',
            hateful: 'Hateful or abusive content',
            harassment: 'Harassment or bullying',
            harmful: 'Harmful or dangerous acts',
            childAbuse: 'Child abuse',
            infrigeRight: 'Infringes my rights',
            terrorism: 'Promotes terrorism',
            spam: 'Spam or misleading',
        },
        reportComment: {
            commercial: 'Unwanted commercial content or spam',
            sexual: 'Pornography or sexual explicit material',
            childAbuse: 'Child abuse',
            hateSpeech: 'Hate speech or graphic violence',
            harassment: 'Harassment or bullying',
        },
        other: 'Other',

        responseReportTitle: 'We are sorry you had this bad experience on MyOpinion.',
        responseReportDescription:
            'Your report has successfully been sent. We will process it and take further actions if necessary.',

        responseOpinionTitle: 'Opinion reported',
        responseOpinionDescription:
            'We will review and take further actions if necessary.',

        responseCommentTitle: 'Comment reported',
        responseCommentDescription:
            'We will review and take further actions if necessary.',

        responseConversationTitle: 'Conversation reported',
        responseConversationDescription:
            'We will review and take further actions if necessary.',

        enter_the_reason: 'Enter the reason...',


        opinion_for_android: 'Opinion for Android',
        opinion_for_ios: 'Opinion for IOS',
        opinion_for_huawei: 'Opinion for Huawei',
        opinion_for_web: 'Opinion for Web',
        opinion_for: 'Opinion for',

        search: 'Search',
        people: 'People',
        peoples: 'Peoples',
        search_by_badge: 'Search by badge',
        rh_search: 'RH Search',
        no_result: 'No result',

        people_you_match: 'People you match',
        colleagues_who_match: 'Colleagues who match',

        my_agoras: 'My groups',
        my_requests: 'My requests',
        my_invites: 'My invites',
        create_agora: 'Create groups',
        create: 'Create',
        title: 'Title',
        participate: 'Participate',
        agora_you_might_be_interested_in: 'Agora you might be interested in',
        show_your_last_seen: 'Show your last seen',
        hide_your_last_seen: 'Hide your last login',
        delete_all_messages: 'Delete all messages',
        write_your_message: 'Write your message',
        member: 'Member',
        members: 'Members',
        view_all: 'View all',
        poll: 'Poll',

        privacy: 'Privacy',
        event: 'Event',
        event_title: 'Event title',
        event_type: 'Event type',
        public_event: 'Public event',
        location_of_the_event: 'Event type',
        event_date: 'Event date',
        event_time: 'Event time',

        enter_the_event_title: 'Enter the event title.',
        enter_the_event_description: 'Enter the event description.',
        enter_the_location_Of_the_event: 'Enter the location Of the event.',
        enter_the_date_of_the_event: 'Enter the date of the event.',
        enter_the_time_of_the_event: 'Enter the time of the event.',

        participate: 'Participate',
        request_sent: 'Request sent',


        // INTRANET

        myRequests: 'My requests',
        myRequestDesc: 'Management of HR requests: contract, leave request, communication etc.',
        myBalance: 'My holiday balance',
        myBalanceDesc: 'This service allows you to securely view your monthly pay slips.',
        myEvents: 'My events',
        myEventDesc: 'Discover all our activities for your corporate events and book an incredible moment in just a few clicks.',
        myCalendar: 'My calendar',
        myCalendarDesc: 'A useful scheduling tool for scheduling employee shifts.',
        sharedDocuments: 'Shared documents',
        sharedDocumentDesc: 'NSIA Docs allows you to collaborate in documents.',
        newRequest: 'New request',
        requestsSent: 'Requests sent',
        requestSent: 'Requests sent',
        requestsReceived: 'Requests received',
        noRequestAvailable: 'No request available',
        noEventAvailable: 'No event available',
        weEncounteredAnErrorPleaseTryAgain: 'We encountered an error, please try again',
        treatmentFailure: 'Treatment failure',
        requestSubmitted: 'Request submitted',
        theRequestHasBeenSubmittedSuccessfully: 'The request has been submitted successfully.',
        requestValidated: 'Request validated',
        validateRequest: 'Validate request',
        rejectRequest: 'Reject request',
        treatRequest: 'Treat request',

        theRequestHasBeenSuccessfullyValidated: 'The request has been successfully validated.',
        requestRejected: 'Request rejected',
        theRequestWasSuccessfullyRejected: 'The request was successfully rejected.',
        requestDeleted: 'Request deleted',
        theRequestWasSuccessfullyDeleted: 'The request was successfully deleted.',

        subjectRequest: 'Subject request',
        descriptionRequest: 'Description request',
        optional: 'Optional',
        mandatory: 'Mandatory',

        requestSentBy: 'Request sent by',
        mailAddress: 'Mail address',

        // STATUS
        draft: 'Draft',
        pending: 'Pending',
        participate: 'Participate',
        approve: 'Approve',
        dismiss: 'Dismiss',
        validate: 'Validate',
        validated: 'Validated',
        treated: 'Treated',
        rejected: 'Rejected',
        requestType: 'Request type',
        statusRequest: 'Status request',
        documentType: 'Document type',
        administrativeDocument: 'Administrative document',

        departureDate: 'Date of departure',
        departureTime: 'Departure time',
        returnDate: 'Return date',
        returnTime: 'Return time',

        requestDetails: 'Request Details',
        eventDetails: 'Event details',

        pendingRequest: 'Pending request',
        pendingRequests: 'Pending requests',
        requestApproved: 'Request approved',
        requestsApproved: 'Requests approved',

        location: 'Location',
        jour: 'day',
        jours: 'days',
        date: 'Date',
        at: 'at',

        mandatory: 'Mandatory',
        submit: 'Submit',
        delete: 'Delete',
        numberOfDays: 'Number of days',

        select_the_type_of_request: 'Select the type of request',
        select_the_document_type: 'Select the document type.',
        enter_departure_date: 'Enter departure date.',
        enter_departure_time: 'Enter departure time.',
        enter_return_date: 'Enter return date.',
        enter_return_time: 'Enter return time.',

        selectTheDocument: 'Select the document',
        acquiredDayOff: 'Acquired day off',
        lastupdatedOn: 'Last updated on',

        myAIAssistant: 'My AI Assistant',
        myAIAssistantDesc: 'Smart computer virtual assistant. Solve your most common IT queries by automating them with a smart chatbot.',
        chatDesc: 'Learn how to use chat based language models.',

        textCompletion: 'Text completion',
        textCompletionDesc: 'Learn how to generate or edit text.',

        embeddings: 'Embeddings',
        embeddingsDesc: 'Learn how to search, classify and compare text.',

        speechToText: 'Speech to text',
        speechToTextDesc: 'Learn how to turn audio into text.',


        imageGeneration: 'Image generation',
        imageGenerationDesc: 'Learn how to generate or edit images.',

        fineTuning: 'Fine-tuning',
        fineTuningDesc: 'Learn how to train a model for your use case.',

    }
}
export default en;