import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.style.css'
import { isMobile } from 'react-device-detect';
import Feed from '../../components/Feed/Feed';
import AppStore from '../../components/AppStore/AppStore';
import LoginForm from '../../components/LoginForm/LoginForm';

import playstore from '../../assets/images/playstore.png';
import appstore from '../../assets/images/appstore.png';
import appgallery from '../../assets/images/appgallery.png';
import { useTranslation } from 'react-i18next';



const Login = () => {

  const {t} = useTranslation()

  /* RENDER MOBILE */
  if (isMobile) {
    return (
      <div className='mobile-login'>
        
        {/* <div className='store'>
          <span className='title'>{t('download_myopinion')}</span>
          <div className='container-logo'>
            <a href="https://play.google.com/store/apps/details?id=com.myopinion.ci">
              <img className='img-playstore' src={playstore} alt='' />
            </a>
            <a href="https://apps.apple.com/fr/app/myopinion-r%C3%A9seau-social/id1608191428">
              <img className='img-appstore' src={appstore} alt='' />
            </a>
            <a href="https://appgallery.cloud.huawei.com/ag/n/app/C107058367?locale=en_US&source=appshare&subsource=C107058367&shareTo=com.android.bluetooth&shareFrom=appmarket&shareIds=06b3f9ddf0b14366b30a9f9f189cd555_com.android.bluetooth&callType=SHARE">
              <img className='img-appgallery' src={appgallery} alt='' />
            </a>
          </div>
        </div> */}
        
        <LoginForm />

      </div>
    )
  }


  return (
    <div className='login'>

      {/* RENDER SOTRE */}
      <div className='card-store'>
        <AppStore />
      </div>

      {/* RENDER OPINION */}
      <div className='card-opinion'>
        <Feed />
      </div>

      {/* RENDER LOGIN */}
      <div className='card-login'>
        <LoginForm />
      </div>

    </div >
  )
}

export default Login