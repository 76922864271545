import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import styles from './SearchRH.module.css'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { api } from '../../api/api'
import AutoCompleteEmployers from '../../components/AutoCompleteEmployers/AutoCompleteEmployers'
import ButtonSubmit from '../../components/ButtonSubmit/ButtonSubmit'
import ProfileCard from '../../components/ProfileCard/ProfileCard'
import { color } from '../../hooks/Utils/color'
import { IoClose } from 'react-icons/io5'
import { MdAdd } from 'react-icons/md'



const SearchRH = () => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    const [people, setPeople] = useState([])
    const [resultText, setResultText] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const [employer, setEmployer] = useState(user?.user_employer)
    const [direction, setDirection] = useState(null)
    const [department, setDepartment] = useState(null)
    const [service, setService] = useState(null)

    const [showOption2, setShowOption2] = useState(false)
    const [showOption3, setShowOption3] = useState(false)
    const [showOption4, setShowOption4] = useState(false)


    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)


    const handleSearch = async () => {
        try {
            setLoading(true)
            const body = {
                employer,
                direction: direction || null,
                department: department || null,
                service: service || null,
            }
            console.log(body)
            const request = await api(`api/search/search`, 'POST', body, accessToken, signal)
            const response = await request.json()
            console.log('Search response:', response)
            setLoading(false)
            if (request?.ok && request?.status === 200) {
                setPeople(response?.search_person)
                if (response?.search_person.length === 0) {
                    setResultText(t('no_result'))
                } else {
                    setResultText(`${response?.search_person?.length} ${response?.search_person.length === 1 ? t('people') : t('peoples')}`)
                }
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        }
    }

    const handleShowOption = () => {
        if (!showOption2) {
            setShowOption2(true)
        }
        if (showOption2 && !showOption3) {
            setShowOption3(true)
        }
        if (showOption3 && !showOption4) {
            setShowOption4(true)
        }
    }


    return (
        <div className={styles.screen}>
            <Header title={t('rh_search')} goBack={goBack} />
            <div className={styles.container}>

                <div style={{marginRight: '10%', marginLeft: '10%'}}>


                    <div className={styles.inputContainer}>
                        <div style={{ width: '95%' }}>
                            <AutoCompleteEmployers
                                title={t('compagny_or_entity')}
                                value={employer}
                                setValue={setEmployer}
                            />
                        </div>
                        <button onClick={handleShowOption} className={styles.addButton}>
                            <MdAdd size={20} color='white' />
                        </button>
                    </div>


                    {showOption2 &&
                        <div className={styles.inputContainer}>
                            <div style={{ width: '95%' }}>
                                <AutoCompleteEmployers
                                    title={t('division')}
                                    type='directions'
                                    value={direction}
                                    setValue={setDirection}
                                />
                            </div>
                            <button
                                onClick={() => {
                                    setShowOption2(false)
                                    setDirection(null)
                                }}
                                className={styles.closeButton}>
                                <IoClose size={20} color='white' />
                            </button>
                        </div>
                    }

                    {showOption3 &&
                        <div className={styles.inputContainer}>
                            <div style={{ width: '95%' }}>
                                <AutoCompleteEmployers
                                    title={t('department')}
                                    type='departments'
                                    value={department}
                                    setValue={setDepartment}
                                />
                            </div>
                            <button
                                onClick={() => {
                                    setShowOption3(false)
                                    setDepartment(null)
                                }}
                                className={styles.closeButton}>
                                <IoClose size={20} color='white' />
                            </button>
                        </div>
                    }

                    {showOption4 &&
                        <div className={styles.inputContainer}>
                            <div style={{ width: '95%' }}>
                                <AutoCompleteEmployers
                                    title={t('unit')}
                                    type='services'
                                    value={service}
                                    setValue={setService}
                                />
                            </div>
                            <button
                                onClick={() => {
                                    setShowOption4(false)
                                    setService(null)
                                }}
                                className={styles.closeButton}>
                                <IoClose size={20} color='white' />
                            </button>
                        </div>
                    }

                    <ButtonSubmit
                        value={t('search')}
                        onClick={handleSearch}
                        isLoading={isLoading}
                        style={{
                            width: '40%',
                            color: 'white',
                            fontWeight: '600',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <b style={{ color: color.primary, textAlign: 'center' }}>
                            {resultText}
                        </b>
                    </div>

                </div>

                <div style={{ paddingTop: '20px', marginTop: '10px', borderTop: '1px solid #F1F1F1' }}>
                    {people.map((user, index) => {
                        return (
                            <ProfileCard
                                key={index}
                                user={user}
                                pictureSize={50}
                            />
                        )
                    })}
                </div>


            </div>

        </div>
    )
}

export default SearchRH