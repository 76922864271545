import React, { useEffect, useState } from 'react'
import SondVideo from '../OpinionBody/SondVideo/SondVideo'

const SondAttachment = ({
    attachment,
    width = 100,
    height = '400px'
}) => {



    const RenderAttachment = (item) => {
        if (item?.attach_type === "image") {
            return (
                <div style={{ backgroundColor: '#f1f1f1' }}>
                    <img
                        src={item?.attach_url}
                        alt=''
                        style={{
                            width: `${width}%`,
                            height: height,
                            objectFit: 'cover',
                            border: '1px white solid'
                        }} />
                </div>
            )
        }

        if (item?.attach_type === "video") {
            return (
                <div style={{ width: `${width}%`, height: height, border: '1px white solid' }}>
                    <SondVideo
                        uri={item?.attach_url}
                        thumbnail={item?.attach_thumbnail}
                        width={`${width}%`}
                        height={height}
                    />
                </div>
            )
        }
    }


    return (
        <div>
            {RenderAttachment(attachment)}
        </div>
    )
}

export default SondAttachment