import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit'
import Header from '../Header/Header'
import TextInput from '../TextInput/TextInput'
import Select from 'react-select'
import { api } from '../../api/api'
import { ValidateEmail, ValidatePseudo } from '../../hooks/Utils/parsing'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction'
import i18next from 'i18next'
import { color } from '../../hooks/Utils/color'
import { Link, useNavigate } from 'react-router-dom'
import { BsFillBookFill, BsPatchCheck } from 'react-icons/bs'
import { RiBookLine } from 'react-icons/ri'
import { AiOutlineSecurityScan } from 'react-icons/ai'
import { BiLockAlt } from 'react-icons/bi'
import { IoWalletOutline } from 'react-icons/io5'
import ModelAlert from '../ModalAlert/ModelAlert'
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';

const ModalSetting = ({
    user,
    likes,
    show = false,
    setShow,
    refresh
}) => {

    const { data: authData } = useAuthReducer()
    const language = authData?.language
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const { UPDATE_LANGUAGE, LOGOUT } = useReducerAuthAction()
    const [error, setError] = useState('')

    const [showAlert, setShowAlert] = useState('')

    const [userEmailNotification, setUserEmailNotification] = useState(0)

    const languages = [
        {
            label: t('french_france'),
            value: 'fr'
        },
        {
            label: t('english_us'),
            value: 'en'
        },
    ]

    const suscriptions = [
        {
            value: 1,
            label: t('yes')
        },
        {
            value: 0,
            label: t('no'),
        },
    ]

    /* CHANGE LANGUAGE */
    const handleChangeLanguage = (value) => {
        i18next.changeLanguage(value)
        UPDATE_LANGUAGE(value)
    }


    useEffect(() => {
        if (!show) {
            return
        }
        setIsLoading(true)
        fetchSetting()
    }, [show])

    const fetchSetting = async () => {
        try {
            console.log('Fetch setting...')
            const request = await api(`api/user/get-user-settings/${user?.user_id}`, 'GET', null, accessToken)
            const response = await request.json()
            console.log('Response user settings:', response)
            if (request.status === 200) {
                if (response.success) {
                    setUserEmailNotification(response.user_settings.user_email_notification)
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            setShow(false)
            console.error('Error fetch setting:', error);
        }
    }

    const updateSetting = async (body) => {
        try {
            const request = await api(`api/user/set-user-settings`, 'POST', body, accessToken)
            const response = await request.json()
            console.log('Res^ponse update settings:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchSetting()
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const logoutAccount = () => {
        try {
            if (authData?.user?.user_oauth_provider === 'facebook') {
                FacebookLoginClient.logout((res) => {
                    console.log('logout completed.');
                });
            }
            navigate('/')
            LOGOUT()
        } catch (error) {
            console.error(error);
        }
    }


    const renderLoading = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '170px' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '30px',
                            height: '30px'
                        }}
                    />
                </div>
            </div>
        )
    }


    return (
        <>
            <Modal scrollable={true} show={show}>

                <Header title={t('setting')} type='modal' goBack={() => setShow(false)} />

                <span style={{ textAlign: 'center', color: 'red', marginTop: '10px' }}>{error}</span>

                {isLoading ? renderLoading() :

                    <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '3%' }}>

                        <div style={{ marginBottom: '3%' }}>
                            <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                                {t('language')}
                            </span>
                            <Select
                                placeholder='Langue'
                                options={languages}
                                isSearchable={false}
                                defaultValue={languages.filter(item => item.value == language)}
                                onChange={newValue => {
                                    handleChangeLanguage(newValue.value)
                                    updateSetting({ user_langue: newValue.value, user_lang_session: newValue.value })
                                }}
                                className='select-language'
                            />
                        </div>

                        <div style={{ marginBottom: '3%' }}>
                            <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                                {t('subscribe_to_email_notification')}
                            </span>
                            <Select
                                placeholder='Langue'
                                options={suscriptions}
                                isSearchable={false}
                                defaultValue={suscriptions.filter(items => items.value === userEmailNotification)}
                                onChange={newValue => {
                                    setUserEmailNotification(newValue.value)
                                    updateSetting({ user_email_notification: newValue.value })
                                }}
                                className='select-language'
                            />
                        </div>


                        <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                            Règles communautaires et politiques de confidentialité
                        </span>

                        <br />

                        <a href={language === 'en' ? 'https://myopinion.ci/legal/en/terms-and-conditions/' : 'https://myopinion.ci/legal/fr/conditions-generales/'}
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '15px' }}>
                            <RiBookLine size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('terms_and_conditions')}
                            </span>
                        </a>

                        <a href={language === 'en' ? 'https://myopinion.ci/legal/en/privacy-policy/' : 'https://myopinion.ci/legal/fr/politique-de-confidentialite/'}
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <AiOutlineSecurityScan size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('privacy_policy')}
                            </span>
                        </a>

                        <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.secondary }}>
                            {t('account')}
                        </span>

                        <br />

                        <div onClick={() => navigate(`/profiles/user/${user?.user_id}/change-password`)} /*  to={`change-password`} */ style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '15px' }}>
                            <BiLockAlt size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('security_and_password')}
                            </span>
                        </div>

                        <div onClick={() => navigate(`/profile/${user?.user_id}/transactions`, { state: { likes } })} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <IoWalletOutline size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('balance')}
                            </span>
                        </div>

                        <Link
                            onClick={() => {
                                setShowAlert(true)
                                setShow(false)
                            }}
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                            <BsPatchCheck size={24} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('subscriptions')}
                            </span>
                        </Link>


                        <ButtonSubmit
                            onClick={logoutAccount}
                            value={t('logout')}
                            isLoading={isLoading}
                            loadBoostrapColor={'light'}
                            scrollable={false}
                            style={{
                                width: '100%',
                                color: 'white',
                                marginBottom: '5%',
                                backgroundColor: '#1880a1'
                            }}
                        />

                    </div>}
            </Modal>

            <ModelAlert
                message={language == 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />
        </>
    )
}

export default ModalSetting