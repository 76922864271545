import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillLike, AiLike, AiOutlineLike, AiOutlineClose } from 'react-icons/ai'
import logo from '../../assets/images/logo.jpg';
import { useTranslation } from 'react-i18next';


const ModelUnauth = ({
    show = false,
    setShow = () => null
}) => {

    const {t} = useTranslation()

    return (
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="show-grid">
                <Container>

                    <button onClick={() => setShow(false)} style={{ position: 'absolute' }}>
                        <AiOutlineClose size={24} color={'#808080'} />
                    </button>

                    <div style={{ textAlign: 'center', paddingTop: '8%', paddingBottom: '8%' }}>
                        <img width={100} src={logo} alt='' />
                        <h5 style={{ textAlign: 'center' }}>
                            {t('login_now_to_interact_with_thousands_of_people')}
                        </h5>
                    </div>

                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default ModelUnauth;