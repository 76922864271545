import { createStore } from 'redux'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import AuthReducer from '../reducers/AuthReducer'
import HomeReducer from '../reducers/HomeReducer'
import RandomReducer from '../reducers/RandomReducer'
import RecentReducer from '../reducers/RecentReducer'
import ViralReducer from '../reducers/ViralReducer'
import ReducerNotification from '../reducers/ReducerNotification'
import PeopleMatchReducer from '../reducers/PeopleMatchReducer'
import OneToOneReducer from '../reducers/OneToOneReducer'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['HomeReducer', 'RandomReducer', 'RecentReducer', 'ViralReducer']
}

const persistedReducer = persistCombineReducers(persistConfig, {
    AuthReducer,
    HomeReducer,
    RandomReducer,
    RecentReducer,
    ViralReducer,
    ReducerNotification,
    PeopleMatchReducer,
    OneToOneReducer
})

const store = createStore(persistedReducer)
const persistor = persistStore(store)

export default store;
export { persistor };