import React, { useState } from 'react';
import './ViewOpinion.style.css'
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import Opinion from '../Opinion/Opinion';
import { Modal, Spinner } from 'react-bootstrap';
import { FaGalacticSenate } from 'react-icons/fa';
import Sidebar from '../Sidebar/Sidebar';
import Endbar from '../Endbar/Endbar';
import { useEffect } from 'react';
import { api } from '../../api/api';
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks';
import Comment from '../Comment/Comment';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const ViewOpinion = ({
    item,
    show,
    setShow,
    updateOpinion,
    sourceFrom,
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const [comments, setComments] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!show) {
            return
        }
        const fetchComments = async () => {
            try {
                const request = await api(`api/opinions/allcomments/${item?.sond_id}`, 'GET', null, accessToken);
                const response = await request.json()
                console.log('Response all comments: ', response)
                if (response.success) {
                    setComments(response.comments)
                }
                setIsLoading(false)
            } catch (error) {
                console.error('Get opinion comments:', error.message);
            }
        }
        fetchComments()
    }, [show])




    const renderLoading = () => {
        return (
            <div style={{ borderLeft: '1px #f1f1f1 solid', borderRight: '1px #f1f1f1 solid' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant='secondary'
                            style={{
                                width: '30px',
                                height: '30px'
                            }}
                        />
                        <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
                            {t('loading_opinion')}
                        </h6>
                    </div>
                </div>
            </div>
        )
    }

    if (isMobile) {
        return (
            <Modal show={show} fullscreen={true} animation={false}>

                <div className='content'>

                    <div style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'fixed',
                        border: '1px #f1f1f1 solid',
                        height: '50px',
                        backgroundColor: 'white',
                        paddingLeft: '10px',
                    }}>
                        <div onClick={() => setShow(false)} className='button-back' >
                            <MdOutlineKeyboardBackspace size={32} />
                        </div>
                        <h5 className='title' style={{ textAlign: 'center' }}>
                            Opinion
                        </h5>
                    </div>

                    <div className='view' style={{ marginTop: '50px' }}>

                        {isLoading ? renderLoading() :

                            <>
                                <Opinion
                                    item={item}
                                    opinion={item}
                                    updateOpinion={updateOpinion}
                                    sourceFrom='ViewOpinion'
                                />

                                {comments.map(comment => {
                                    return (
                                        <div>
                                            <Comment
                                                user={comment?.user}
                                                post_id={item?.sond_id}
                                                com_id={comment?.com_id}
                                                com_mute={comment?.com_mute}
                                                com_parent={comment?.com_parent}
                                                com_sond={comment?.com_sond}
                                                com_text={comment?.com_text}
                                                com_type={comment?.com_type}
                                                com_user={comment?.com_user}
                                                attachment={comment?.attachment}
                                                soutiens={comment?.soutiens}
                                                soutiens_count={comment?.soutiens_count}
                                                subcomments={comment?.subcomments}
                                                subcomments_count={comment?.subcomments_count}
                                                com_date_created={comment?.com_date_created}
                                                sourceFrom={sourceFrom}
                                            />

                                            {comment?.subcomments.map(subcomment => {
                                                return (
                                                    <div style={{ marginLeft: '55px' }}>
                                                        <Comment
                                                            user={subcomment?.user}
                                                            post_id={item?.sond_id}
                                                            com_id={subcomment?.com_id}
                                                            com_mute={subcomment?.com_mute}
                                                            com_parent={comment?.com_id}
                                                            com_sond={subcomment?.com_sond}
                                                            com_text={subcomment?.com_text}
                                                            com_type={subcomment?.com_type}
                                                            com_user={subcomment?.com_user}
                                                            attachment={subcomment?.attachment}
                                                            soutiens={subcomment?.soutiens}
                                                            soutiens_count={subcomment?.soutiens_count}
                                                            subcomments={subcomment?.subcomments}
                                                            subcomments_count={subcomment?.subcomments_count}
                                                            com_date_created={subcomment?.com_date_created}
                                                            sourceFrom={sourceFrom}
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        }

                    </div>
                </div>
            </Modal>
        )
    }


    return (
        <Modal show={show} fullscreen={true} animation={false}>
            <div className='view-opinion'>
                <Sidebar />
                <div className='content'>

                    <div className='topbar'>
                        <div onClick={() => setShow(false)} className='button-back' >
                            <MdOutlineKeyboardBackspace size={32} />
                        </div>
                        <h5 className='title'>
                            Opinion
                        </h5>
                    </div>

                    <div className='view'>

                        {isLoading ? renderLoading() :

                            <>
                                <Opinion
                                    item={item}
                                    updateOpinion={updateOpinion}
                                    sourceFrom='ViewOpinion'
                                />

                                {comments.map(comment => {
                                    return (
                                        <div>
                                            <Comment
                                                user={comment?.user}
                                                com_id={comment?.com_id}
                                                com_mute={comment?.com_mute}
                                                com_parent={comment?.com_parent}
                                                com_sond={comment?.com_sond}
                                                com_text={comment?.com_text}
                                                com_type={comment?.com_type}
                                                com_user={comment?.com_user}
                                                attachment={comment?.attachment}
                                                soutiens={comment?.soutiens}
                                                soutiens_count={comment?.soutiens_count}
                                                subcomments={comment?.subcomments}
                                                subcomments_count={comment?.subcomments_count}
                                                com_date_created={comment?.com_date_created}
                                                sourceFrom={sourceFrom}
                                            />

                                            {comment?.subcomments.map(subcomment => {
                                                return (
                                                    <div style={{ marginLeft: '55px' }}>
                                                        <Comment
                                                            user={subcomment?.user}
                                                            com_id={subcomment?.com_id}
                                                            com_mute={subcomment?.com_mute}
                                                            com_parent={comment?.com_id}
                                                            com_sond={subcomment?.com_sond}
                                                            com_text={subcomment?.com_text}
                                                            com_type={subcomment?.com_type}
                                                            com_user={subcomment?.com_user}
                                                            attachment={subcomment?.attachment}
                                                            soutiens={subcomment?.soutiens}
                                                            soutiens_count={subcomment?.soutiens_count}
                                                            subcomments={subcomment?.subcomments}
                                                            subcomments_count={subcomment?.subcomments_count}
                                                            com_date_created={subcomment?.com_date_created}
                                                            sourceFrom={sourceFrom}
                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })}
                            </>
                        }

                    </div>
                </div>
                <Endbar />
            </div>
        </Modal>

    );
}

export default ViewOpinion