import React from 'react'
import { Outlet } from 'react-router-dom'
import './AppLayout.style.css'
import NavigationBottom from 'components/NavigationButton/NavigationBottom';
import Sidebar from 'components/Sidebar/Sidebar';
import Endbar from 'components/Endbar/Endbar';
import { isMobile } from 'react-device-detect';


function AppLayout() {


    const currentPath = window.location.pathname;

    if (isMobile) {
        return (
            <div>
                <Outlet />
                <NavigationBottom/>
            </div>
        )
    }

    return (
        <div className='app-layout'>

            <div className='sidebar-box'>
                <Sidebar />
            </div>

            <div className='outlet'>
                <Outlet />
            </div>

            <div className='endbar-box'>
                <Endbar />
            </div>
        </div>
    )
}

export default AppLayout