const Constants = {

    // API MANAGER
    API_URL: 'https://api.nsia.socialhub.ci',
    // API_URL: 'https://nsiasocialhub.myopinion.jdavprojects.com',
    
    // CONSTANTS API URL
    GOOGLE_ID_CLIENT: "347464821035-sj575a7gd7oau7cuau02frrd8nroaljn.apps.googleusercontent.com",
    APPLE_ID_CLIENT: "com.myopinion.web.ci",
    FACEBBOK_APP_ID: "854912335236117", // PROD
    // FACEBBOK_APP_ID: "1172208290150869", // DEV
    
    REDIRECT_URI: 'https://myopinion.ci',
    HOSTNAME: window.location.protocol + "//" + window.location.hostname,
    
}

export default Constants;


