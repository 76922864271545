import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import './ViralOpinions.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Opinion from '../../../Opinion/Opinion'
import { api } from '../../../../api/api'
import { useAccessToken, useViralReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import useReducerViralAction from '../../../../hooks/ReducerHooksAction/useReducerViralAction'

const RandomOpinions = () => {

    // STATE
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: ViralData } = useViralReducer()
    const { REFRESH_VIRAL_OPINIONS, DELETE_VIRAL_OPINION, MUTED_USER_VIRAL_OPINION, ADD_VIRAL_OPINIONS, UPDATE_VIRAL_OPINION } = useReducerViralAction()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(true)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        if (isRefresh) {
            setIsLoading(true)
        }
        try {
            const request = await api(`api/opinions/v2/public?page=${page}`, 'GET', null, accessToken)
            const response = await request.json()
            console.log('Response fetch opinions random:', response)
            if (request.status === 200) {
                if (response.success) {

                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }

                    if (response?.opinions?.data?.lengh === 0) {
                        setIsEmpty(true)
                        return
                    }

                    if (response?.opinions?.current_page == 1) {
                        REFRESH_VIRAL_OPINIONS(response?.opinions?.data)
                    } else {
                        ADD_VIRAL_OPINIONS(response?.opinions?.data)
                    }

                    setPage(item => item + 1)

                }
            }
            setIsRefresh(false)
            setIsLoading(false)
        } catch (error) {
            fetchOpinions()
            setIsLoading(false)
            console.warn(error);
        }
    };


    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '30px',
                            height: '30px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
                        {t('loading_viral')}
                    </h6>
                </div>
            </div>
        )
    }


    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '5%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }

    return (
        <div id="ViralScrollableDiv" className='viral-opinions'>
            <InfiniteScroll
                dataLength={ViralData?.opinions.length}
                next={fetchOpinions}
                hasMore={hasMore}
                loader={renderLoader()}
                scrollableTarget="ViralScrollableDiv"
                scrollThreshold={0.9}
                style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}>
                {ViralData?.opinions.map((item, index) => {
                    return (
                        <Opinion
                            key={index}
                            item={item}
                            opinion={item}
                            index={index}
                            updateOpinion={UPDATE_VIRAL_OPINION}
                            deleteOpinion={DELETE_VIRAL_OPINION}
                            handleMuteUser={MUTED_USER_VIRAL_OPINION}
                            sourceFrom={'Public'}
                        />
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

export default RandomOpinions