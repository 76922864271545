import React from 'react'
import './Public.style.css'
import PublicOpinions from '../../components/Opinions/PublicOpinions/PublicOpinions';
import { useTranslation } from 'react-i18next';

const Public = () => {

  // STATE
  const { t } = useTranslation()

  return (
    <div className='public'>
      {/* <div className='header'>
        <h5 className='title'>
          {t('public')}
        </h5>
      </div> */}
      <PublicOpinions />
    </div>
  )
}

export default Public