import React, { useEffect, useState } from 'react'
import './ModalCreateOpinion.style.css'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit'
import Header from '../Header/Header'
import TextInput from '../TextInput/TextInput'
import Select from 'react-select'
import { api } from '../../api/api'
import { ValidateEmail, ValidatePseudo } from '../../hooks/Utils/parsing'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction'
import i18next from 'i18next'
import { color } from '../../hooks/Utils/color'
import ProfileCard from '../ProfileCard/ProfileCard'
import { IoCloseSharp } from 'react-icons/io5'
import { RiImageAddFill } from 'react-icons/ri'
import { Mention, MentionsInput } from 'react-mentions'
import { BsImages } from 'react-icons/bs'
import { FaPhotoVideo, FaRegFileAudio } from 'react-icons/fa'
import { MdOutlineEmojiEmotions } from 'react-icons/md'
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios'
import Constants from '../../constants/Constants'
import { useNavigate } from 'react-router-dom'
import Compressor from 'compressorjs'
import VideoThumbnail from 'react-video-thumbnail';
import ModelAlert from '../ModalAlert/ModelAlert'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import Switch from "react-switch";
import moment from 'moment'
import dayjs from 'dayjs'



const ModalCreateOpinion = ({
    user,
    sond_id = 0,
    ago_id = 0,
    ago_title = '',
    caption = '',
    picture = null,
    show = false,
    setShow,

    // EVENT INIT STATE
    event_id,
    event_title = '',
    event_descr = '',
    event_localisation = '',
    event_date = '',
    event_time = '',
    event_publish_type = 'PRIVATE',
}) => {


    const { t } = useTranslation()
    const { data: authData } = useAuthReducer()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    // opinion state
    const [sond_group, setSond_group] = useState(ago_id)
    const [sond_caption, setSond_caption] = useState(caption || event_descr)
    const [sond_validity, setSond_validity] = useState(null)
    const [sond_diffusion, setSond_diffusion] = useState(0)

    // AUDIO STATE
    const [sond_audio, setSond_audio] = useState()

    // MEDIA STATE
    const [sond_picture, setSond_picture] = useState(null)
    const [sond_type, setSond_type] = useState()
    const [sond_width, setSond_width] = useState()
    const [sond_height, setSond_height] = useState()
    const [sond_thumbnail, setSond_thumbnail] = useState()
    const [sond_size, setSond_size] = useState()
    const [sond_duration, setSond_duration] = useState()
    const [sond_extension, setSond_extension] = useState()

    // ATTACHEMENTS STATE
    const [attachments, setAttachments] = useState([])
    const [attachmentThumbnail, setAttachmentThumbnail] = useState([])
    const [previewMedia, setPreviewMedia] = useState([])

    // CHOICE STATE
    const [opt_libelle1, setOpt_libelle1] = useState(null)
    const [opt_libelle2, setOpt_libelle2] = useState(null)
    const [opt_libelle3, setOpt_libelle3] = useState(null)
    const [opt_libelle4, setOpt_libelle4] = useState(null)
    const [opt_libelle5, setOpt_libelle5] = useState(null)
    const [opt_libelle6, setOpt_libelle6] = useState(null)

    const [opt_image1, setOpt_image1] = useState(null)
    const [opt_image2, setOpt_image2] = useState(null)
    const [opt_image3, setOpt_image3] = useState(null)
    const [opt_image4, setOpt_image4] = useState(null)
    const [opt_image5, setOpt_image5] = useState(null)
    const [opt_image6, setOpt_image6] = useState(null)

    const [opt_thumbnail1, setOpt_thumbnail1] = useState(null)
    const [opt_thumbnail2, setOpt_thumbnail2] = useState(null)
    const [opt_thumbnail3, setOpt_thumbnail3] = useState(null)
    const [opt_thumbnail4, setOpt_thumbnail4] = useState(null)
    const [opt_thumbnail5, setOpt_thumbnail5] = useState(null)
    const [opt_thumbnail6, setOpt_thumbnail6] = useState(null)

    const [previewOption1, setPreviewOption1] = useState(null)
    const [previewOption2, setPreviewOption2] = useState(null)
    const [previewOption3, setPreviewOption3] = useState(null)
    const [previewOption4, setPreviewOption4] = useState(null)
    const [previewOption5, setPreviewOption5] = useState(null)
    const [previewOption6, setPreviewOption6] = useState(null)

    const [errorOption, setErrorOption] = useState('')


    // EVENT STATE
    const [sond_title, setSond_title] = useState(event_title);
    const [sond_place, setSond_place] = useState(event_localisation);
    const [sond_date, setSond_date] = useState(event_date ? moment(event_date, 'yyyy-MM-ddThh:mm:ssZ').format('YYYY-MM-DD') : '');
    const [sond_time, setSond_time] = useState(event_time);
    const [event_type, setEvent_type] = useState(event_publish_type);

    const [eventErrors, setEventErrors] = useState({});


    const [pickerDateVisible, setPickerDateVisibility] = useState(false);
    const [pickerTimeVisible, setPickerTimeVisibility] = useState(false);

    // OPTION STATE
    const [eventIsActive, setEventIsActive] = useState(event_id ? true : false)
    const [choiceIsActive, setChoiceIsActive] = useState(false)

    const [stateCreation, setStateCreation] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [showAlertVideo, setShowAlertVideo] = useState(false)


    const [buddies, setBuddies] = useState([])
    const [countries, setCountries] = useState([])

    const [ffmpegLoadded, setffmpegLoadded] = useState(false)

    const goBack = () => {
        setShow(false)
        setError('')
        setEventErrors({})
        // setSond_title('')
        // setSond_place('')
        // setSond_date('')
        // setSond_time('')
        // setEvent_type('PUBLIC')
        // setSond_caption('')

        setAttachments([])
        setPreviewMedia([])
        setChoiceIsActive(false)

        setSond_audio(null)
        setSond_picture(null)
        setSond_thumbnail(null)
        setAttachmentThumbnail([])

        // OPTION
        setOpt_libelle1(null)
        setOpt_libelle2(null)
        setOpt_libelle3(null)
        setOpt_libelle4(null)
        setOpt_libelle5(null)
        setOpt_libelle6(null)

        setOpt_image1(null)
        setOpt_image2(null)
        setOpt_image3(null)
        setOpt_image4(null)
        setOpt_image5(null)
        setOpt_image6(null)

        setOpt_thumbnail1(null)
        setOpt_thumbnail2(null)
        setOpt_thumbnail3(null)
        setOpt_thumbnail4(null)
        setOpt_thumbnail5(null)
        setOpt_thumbnail6(null)

        setPreviewOption1(null)
        setPreviewOption2(null)
        setPreviewOption3(null)
        setPreviewOption4(null)
        setPreviewOption5(null)
        setPreviewOption6(null)
    }

    const diffusion_options = [
        { label: t('buddies'), value: 1 },
        { label: t('public'), value: 0 }
    ]


    const handleChangeEventType = (status) => {
        setEvent_type(status ? 'PUBLIC' : 'PRIVATE')
    }



    useEffect(() => {
        if (!show) {
            return
        }
        const getCountries = async () => {
            try {
                const request = await api('api/get-countries', 'GET', null)
                const response = await request.json()
                // console.log(response, 'responseeeee of get countries')
                if (request.ok && request.status === 200) {
                    const countries = response.countries.map(c => ({
                        label: c.French_Name,
                        value: c.Sort_Order,
                        key: c.ITU_T_Telephone_Code,
                    }))
                    setCountries(countries)
                }
            } catch (error) {
                getCountries()
                console.log('Erreur', error.message)
            }
        }
        getCountries()
    }, [show])



    // UPDATE TAG LIST
    useEffect(() => {
        if (!show) {
            return
        }
        const fetchBuddies = async () => {
            try {
                const request = await api('api/user/get-buddies', 'GET', null, accessToken)
                const response = await request.json()
                // console.log('response buddies:', response)
                if (request.ok && request.status === 200) {
                    const buddiesToStore = response.buddies.map(buddy => ({
                        id: buddy?.user_id,
                        display: `${buddy?.user_username}`,
                        name: buddy?.user_username,
                        prof_picture: buddy?.prof_picture,
                        user_name: buddy?.user_name,
                        user_surname: buddy?.user_surname,
                        user_username: buddy?.user_username,
                        user_verified: buddy?.user_verified,
                        user_match_value: buddy?.user_match_value,
                        user_badge_business: buddy?.user_badge_business,
                        user_badge_color: buddy?.user_badge_color,
                        user_badge_food: buddy?.user_badge_food,
                        user_badge_goal: buddy?.user_badge_goal,
                        user_badge_hobby: buddy?.user_badge_hobby,
                    }))
                    setBuddies(buddiesToStore)
                }
            } catch (error) {
                console.log('Error get-buddies:', error)
                throw new Error(error.message)
            }
        }
        fetchBuddies()
    }, [show])



    const renderSuggestion = (suggestion, index) => {
        return (
            <div key={index.toString()} style={{ display: 'flex', alignItems: 'center', padding: '5px', backgroundColor: '#f1f1f1' }}>
                <ProfilePicture
                    user={suggestion}
                    uri={suggestion?.prof_picture}
                    size={40}
                />
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '13px', marginBottom: '-8px' }}>
                        {`${suggestion?.user_surname} ${suggestion?.user_name}`}
                    </p>
                    <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary, }}>@{suggestion.user_username}</span>
                </div>
            </div>
        )
    }


    const handleFileChange = async (e, source) => {
        if (e.target.files) {

            const file = e.target.files[0]
            const fileUri = window.URL.createObjectURL(file)
            console.log('handleFileChange:', file)

            if (file.type.split("/")[0] === 'image') {



                handleLoadPreview(source, { uri: fileUri, type: 'image' })

                new Compressor(file, {
                    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                    success: async (compressedResult) => {
                        console.log('compressedResult:', compressedResult)
                        const { width, height } = await getImageParams(file)

                        const data = {
                            file: compressedResult,
                            width,
                            height,
                            size: file?.size,
                            type: 'image',
                            extension: file?.type.split("/")[1],
                            mime_type: file?.type
                        }

                        handleLoadFile(source, data)

                    },
                });

            }

            if (file.type.split("/")[0] === 'video') {

                const megabytes = (file.size / 1048576).toFixed(0);
                if (megabytes > 50) {
                    setShowAlertVideo(true)
                } else {
                    // generate video thumbnail
                    const thumbnail = await generateVideoThumbnail(e.target.files[0]);
                    if (thumbnail) {

                        // set video thumbnail
                        const { thumbnailUri, thumbnailFile } = await convertBase64ToFile(thumbnail)

                        handleLoadThumbnail(source, thumbnailFile)

                        handleLoadPreview(source, { uri: thumbnailUri, type: 'image' })

                        const meta = await getVideoMetadata(file)
                        console.log('Video meta:', meta)

                        // load file
                        const data = {
                            file,
                            width: meta?.width,
                            height: meta?.height,
                            duration: meta?.duration,
                            size: file.size,
                            type: 'video',
                            extension: file.type.split("/")[1],
                            mime_type: file.type
                        }

                        handleLoadFile(source, data)

                    }
                }
            }

        }
    };



    const handleLoadPreview = (source, data) => {

        if (source == 'attachment') {
            setPreviewMedia(previewMedia => [...previewMedia, data])
        }
        if (source == 'choice1') {
            setPreviewOption1(data?.uri)
        }
        if (source == 'choice2') {
            setPreviewOption2(data?.uri)
        }
        if (source == 'choice3') {
            setPreviewOption3(data?.uri)
        }
        if (source == 'choice4') {
            setPreviewOption4(data?.uri)
        }
        if (source == 'choice5') {
            setPreviewOption5(data?.uri)
        }
        if (source == 'choice6') {
            setPreviewOption6(data?.uri)
        }

    }

    const handleLoadThumbnail = (source, thumbnail) => {

        if (source == 'attachment' && !sond_picture) {
            setSond_thumbnail(thumbnail)
        }
        if (source == 'attachment' && sond_picture) {
            setAttachmentThumbnail(attachmentThumbnail => [...attachmentThumbnail, thumbnail])
        }
        if (source == 'choice1') {
            setOpt_thumbnail1(thumbnail)
        }
        if (source == 'choice2') {
            setOpt_thumbnail2(thumbnail)
        }
        if (source == 'choice3') {
            setOpt_thumbnail3(thumbnail)
        }
        if (source == 'choice4') {
            setOpt_thumbnail4(thumbnail)
        }
        if (source == 'choice5') {
            setOpt_thumbnail5(thumbnail)
        }
        if (source == 'choice6') {
            setOpt_thumbnail6(thumbnail)
        }
    }


    const handleLoadFile = (source, data) => {
        console.log('Preview media:', previewMedia)

        if (source == 'attachment' && !sond_picture) {
            setSond_picture(data);
        }

        if (source == 'attachment' && sond_picture) {
            setAttachments(attachments => [...attachments, data])
        }

        if (source == 'choice1') {
            setOpt_image1(data)
        }
        if (source == 'choice2') {
            setOpt_image2(data)
        }
        if (source == 'choice3') {
            setOpt_image3(data)
        }
        if (source == 'choice4') {
            setOpt_image4(data)
        }
        if (source == 'choice5') {
            setOpt_image5(data)
        }
        if (source == 'choice6') {
            setOpt_image6(data)
        }
    }



    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.currentTime = 5;
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };



    function getVideoMetadata(file) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                URL.revokeObjectURL(video.src);
                resolve({
                    duration: video.duration,
                    width: video.videoWidth,
                    height: video.videoHeight
                });
            };

            video.onerror = reject;
        });
    }


    const convertBase64ToFile = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const thumbnailFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        const thumbnailUri = URL.createObjectURL(thumbnailFile);
        // setImageUrl(imageUrl);
        // console.log('convertBase64ToFile:', file)
        return { thumbnailUri, thumbnailFile }
    };



    // reading a file to get height and width
    async function getImageParams(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader()

            reader.onload = async (e) => {
                var image = new Image()
                image.src = e.target.result
                await image.decode()

                resolve({ width: image.width, height: image.height })
            }
            reader.readAsDataURL(file)
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (sond_id === 0 && !eventIsActive) {
            handleCreatePost()
            return
        }
        if (sond_id !== 0 && !eventIsActive) {
            handleEditOpinion()
        }
        if (sond_id === 0 && eventIsActive) {
            handleCreateEvent()
            return
        }
        if (sond_id !== 0 && eventIsActive) {
            handleEditEvent()
            return
        }
    }

    const handleCreatePost = async () => {
        try {
            if (!sond_caption.trim()) {
                setError(t('you_must_add_a_description_to_your_opinion'))
                return
            }
            setError('')

            const formData = new FormData();

            formData.append('sond_caption', sond_caption)
            formData.append('sond_group', sond_group)
            formData.append('sond_diffusion', sond_diffusion) // 0 public or 1 private
            formData.append('sond_device_os', 'Web')

            if (authData?.user?.user_gold) {
                formData.append('sond_validity', sond_validity)
            }

            if (sond_audio) {
                formData.append('sond_audio', sond_audio)
            }

            /* SOND PICTURE */
            if (sond_picture) {

                // file,
                // width,
                // height,
                // size: file.size,
                // type: 'image',
                // extension: file.type.split("/")[1],
                // mime_type: file.type

                formData.append('sond_picture', sond_picture?.file)
                formData.append('sond_type', sond_picture?.type)
                formData.append('sond_mime_type', sond_picture?.mime_type)

                if (sond_picture.type === 'image') {
                    formData.append('sond_width', sond_picture?.width)
                    formData.append('sond_height', sond_picture?.height)
                }

                if (sond_picture.type === 'video') {

                    formData.append('sond_thumbnail', sond_thumbnail)

                    formData.append('sond_width', sond_picture?.width)
                    formData.append('sond_height', sond_picture?.height)
                    formData.append('sond_duration', sond_picture?.duration)
                    formData.append('sond_size', sond_picture?.size)
                    formData.append('sond_extension', sond_picture?.extension)
                }

            }



            // ATTACHEMENT
            if (attachments?.length != 0) {

                attachments.forEach((attachment, index) => {
                    console.log('Attachment index:', index + 1)
                    formData.append(`attachment_${index + 1}`, attachment?.file)
                    formData.append(`attachment_type_${index + 1}`, attachment?.type)
                    formData.append(`attachment_mime_type_${index + 1}`, attachment?.mime_type)

                    if (attachment?.type == 'image') {
                        formData.append(`attachment_width_${index + 1}`, attachment?.width)
                        formData.append(`attachment_height_${index + 1}`, attachment?.height)
                    }

                    if (attachment?.type == 'video') {

                        formData.append(`attachment_thumbnail_${index + 1}`, attachmentThumbnail[index])

                        formData.append(`attachment_width_${index + 1}`, attachment?.width)
                        formData.append(`attachment_height_${index + 1}`, attachment?.height)
                        formData.append(`attachment_duration_${index + 1}`, attachment?.duration)
                        formData.append(`attachment_size_${index + 1}`, attachment?.size)
                        formData.append(`attachment_extension_${index + 1}`, attachment?.extension)
                    }

                });
            }




            if (choiceIsActive) {

                // OPTION 1
                if (opt_libelle1?.trim()) {

                    formData.append('opt_libelle1', opt_libelle1?.trim())

                    if (opt_image1) {

                        formData.append('opt_image1', opt_image1?.file)
                        formData.append('opt_type1', opt_image1?.type)
                        formData.append('opt_mime_type1', opt_image1?.mime_type)
                        formData.append('opt_size1', opt_image1?.size)
                        formData.append('opt_extension1', opt_image1?.extenxion)

                        if (opt_image1.type === 'image') {
                            formData.append('opt_width1', opt_image1?.width)
                            formData.append('opt_height1', opt_image1?.height)
                        }
                    }

                    if (opt_image1?.type === 'video') {

                        formData.append('opt_thumbnail1', opt_thumbnail1)

                        formData.append('opt_width1', opt_image1?.width)
                        formData.append('opt_height1', opt_image1?.height)
                        formData.append('opt_duration1', opt_image1?.duration)
                        formData.append('opt_size1', opt_image1?.size)
                        formData.append('opt_extension1', opt_image1?.extension)
                    }

                } else {
                    setErrorOption(t('the_first_two_choices_are_mandatory'))
                    return
                }



                // OPTION 2
                if (opt_libelle2?.trim()) {
                    setErrorOption('')
                    formData.append('opt_libelle2', opt_libelle2?.trim())

                    if (opt_image2) {

                        formData.append('opt_image2', opt_image2?.file)
                        formData.append('opt_type2', opt_image2?.type)
                        formData.append('opt_mime_type2', opt_image2?.mime_type)
                        formData.append('opt_size2', opt_image2?.size)
                        formData.append('opt_extension2', opt_image2?.extension)

                        if (opt_image2?.type === 'image') {
                            formData.append('opt_width2', opt_image2?.width)
                            formData.append('opt_height2', opt_image2?.height)
                        }

                        if (opt_image2.type === 'video') {

                            formData.append('opt_thumbnail2', opt_thumbnail2)

                            formData.append('opt_width2', opt_image2?.width)
                            formData.append('opt_height2', opt_image2?.height)
                            formData.append('opt_duration2', opt_image2?.duration)
                            formData.append('opt_size2', opt_image2?.size)
                            formData.append('opt_extension2', opt_image2?.extension)
                        }

                    }

                } else {
                    setErrorOption(t('the_first_two_choices_are_mandatory'))
                    return
                }

                // OPTION 3
                if (opt_libelle3?.trim()) {

                    formData.append('opt_libelle3', opt_libelle3?.trim())

                    if (opt_image3) {

                        formData.append('opt_image3', opt_image3?.file)
                        formData.append('opt_type3', opt_image3?.type)
                        formData.append('opt_mime_type3', opt_image3?.mime_type)
                        formData.append('opt_size3', opt_image3?.size)
                        formData.append('opt_extension3', opt_image3?.extension)

                        if (opt_image3?.type === 'image') {
                            formData.append('opt_width3', opt_image3?.width)
                            formData.append('opt_height3', opt_image3?.height)
                        }

                        if (opt_image3.type === 'video') {

                            formData.append('opt_thumbnail3', opt_thumbnail3)

                            formData.append('opt_width3', opt_image3?.width)
                            formData.append('opt_height3', opt_image3?.height)
                            formData.append('opt_duration3', opt_image3?.duration)
                            formData.append('opt_size3', opt_image3?.size)
                            formData.append('opt_extension3', opt_image3?.extension)
                        }
                    }

                }


                // OPTION 4
                if (opt_libelle4?.trim()) {

                    formData.append('opt_libelle4', opt_libelle4?.trim())

                    if (opt_image4) {

                        formData.append('opt_image4', opt_image4?.file)
                        formData.append('opt_type4', opt_image4?.type)
                        formData.append('opt_mime_type4', opt_image4?.mime_type)
                        formData.append('opt_size4', opt_image4?.size)
                        formData.append('opt_extension4', opt_image4?.extension)

                        if (opt_image4?.type === 'image') {
                            formData.append('opt_width4', opt_image4?.width)
                            formData.append('opt_height4', opt_image4?.height)
                        }

                        if (opt_image4.type === 'video') {

                            formData.append('opt_thumbnail4', opt_thumbnail4)

                            formData.append('opt_width4', opt_image4?.width)
                            formData.append('opt_height4', opt_image4?.height)
                            formData.append('opt_duration4', opt_image4?.duration)
                            formData.append('opt_size4', opt_image4?.size)
                            formData.append('opt_extension4', opt_image4?.extension)
                        }

                    }

                }


                // OPTION 5
                if (opt_libelle5?.trim()) {

                    formData.append('opt_libelle5', opt_libelle5?.trim())

                    if (opt_image5) {

                        formData.append('opt_image5', opt_image5?.file)
                        formData.append('opt_type5', opt_image5?.type)
                        formData.append('opt_mime_type5', opt_image5?.mime_type)
                        formData.append('opt_size5', opt_image5?.size)
                        formData.append('opt_extension5', opt_image5?.extension)

                        if (opt_image5?.type === 'image') {
                            formData.append('opt_width5', opt_image5?.width)
                            formData.append('opt_height5', opt_image5?.height)
                        }

                        if (opt_image5.type === 'video') {

                            formData.append('opt_thumbnail5', opt_thumbnail5)

                            formData.append('opt_width5', opt_image5?.width)
                            formData.append('opt_height5', opt_image5?.height)
                            formData.append('opt_duration5', opt_image5?.duration)
                            formData.append('opt_size5', opt_image5?.size)
                            formData.append('opt_extension5', opt_image5?.extension)
                        }

                    }

                }


                // OPTION 6
                if (opt_libelle6?.trim()) {

                    formData.append('opt_libelle6', opt_libelle6?.trim())

                    if (opt_image6) {

                        formData.append('opt_image6', opt_image6?.file)
                        formData.append('opt_type6', opt_image6?.type)
                        formData.append('opt_mime_type6', opt_image6?.mime_type)
                        formData.append('opt_size6', opt_image6?.size)
                        formData.append('opt_extension6', opt_image6?.extension)

                        if (opt_image6?.type === 'image') {
                            formData.append('opt_width6', opt_image6?.width)
                            formData.append('opt_height6', opt_image6?.height)
                        }

                        if (opt_image6.type === 'video') {

                            formData.append('opt_thumbnail6', opt_thumbnail6)

                            formData.append('opt_width6', opt_image6?.width)
                            formData.append('opt_height6', opt_image6?.height)
                            formData.append('opt_duration6', opt_image6?.duration)
                            formData.append('opt_size6', opt_image6?.size)
                            formData.append('opt_extension6', opt_image6?.extension)
                        }

                    }

                }

            }


            // formData.append('sond_periode', sond_periode)
            // formData.append('sond_publishing', sond_publishing)
            // formData.append('sond_finished_at', sond_finished_at)
            // formData.append('sond_hash', sond_hash)
            // formData.append('sond_group', sond_group)
            // formData.append('sond_diffusion', sond_diffusion)

            setError('')
            setIsLoading(true)
            setStateCreation(true)
            const response = await axios.post(`${Constants.API_URL}/api/opinions/v2/store`, formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    let pourcentage = Math.round((loaded / total) * 100)
                    setUploadProgress(pourcentage)
                    console.log(Math.round((loaded / total) * 100), '% publication en cours...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            console.log('Response create opinion:', response.data)
            if (response.status === 200) {
                if (response.data.success) {
                    goBack()
                    navigate('home', { options: { replace: true } })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                } else {
                    setError('Cette opinion a déjà été publier.')
                }
            }
            setIsLoading(false)
            setStateCreation(false)
        } catch (response) {
            setStateCreation(false)
            setIsLoading(false)
            console.log(response);
        }
    }


    const handleEditOpinion = async () => {
        try {
            if (!sond_caption.trim()) {
                setError(t('you_must_add_a_description_to_your_opinion'))
                return
            }
            setError('')

            const formData = new FormData();
            formData.append('sond_id', sond_id)
            formData.append('sond_caption', sond_caption)
            formData.append('sond_group', sond_group)
            formData.append('sond_diffusion', sond_diffusion) // 0 public or 1 private

            if (authData?.user?.user_gold) {
                formData.append('sond_validity', sond_validity)
            }

            setError('')
            setIsLoading(true)
            setStateCreation(true)
            const response = await axios.post(`${Constants.API_URL}/api/opinions/${sond_id}/update`, formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    let pourcentage = Math.round((loaded / total) * 100)
                    setUploadProgress(pourcentage)
                    console.log(Math.round((loaded / total) * 100), '% publication en cours...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            console.log('Response create opinion:', response.data)
            if (response.status === 200) {
                if (response.data.success) {
                    goBack()
                    navigate('home', { options: { replace: true } })
                } else {
                    setError('Cette opinion a déjà été publier.')
                }
            }
            setIsLoading(false)
            setStateCreation(false)
        } catch (response) {
            setStateCreation(false)
            setIsLoading(false)
            console.log(response);
        }
    }


    //============================[[ CREATE EVENT ]]=============================//
    const handleCreateEvent = async () => {
        try {
            // CHECK FORM
            if (!sond_title?.trim()) {
                setEventErrors({ title: t('enter_the_event_title') })
                return
            }
            if (!sond_caption?.trim()) {
                setEventErrors({})
                setError(t('enter_the_event_description'))
                return
            }
            if (!sond_place?.trim()) {
                setEventErrors({ location: t('enter_the_location_Of_the_event') })
                return
            }
            if (!sond_date) {
                setEventErrors({ date: t('enter_the_date_of_the_event') })
                return
            }
            if (!sond_time) {
                setEventErrors({ date: t('enter_the_time_of_the_event') })
                return
            }
            setEventErrors({})
            setError('')
            console.log('Create new event...')

            const formData = new FormData();

            formData.append('event_title', sond_title)
            formData.append('event_descr', sond_caption)
            formData.append('event_localisation', sond_place)
            formData.append('event_date', sond_date)
            formData.append('event_time', sond_time)
            formData.append('event_type', event_type)

            formData.append('sond_group', sond_group)
            formData.append('sond_diffusion', sond_diffusion) // 0 public or 1 private
            formData.append('sond_device_os', 'Web')

            if (authData?.user?.user_gold) {
                formData.append('sond_validity', sond_validity)
            }

            if (sond_audio) {
                formData.append('attachment_1', sond_audio)
                formData.append('attachment_type_1', 'audio')
            }

            /* SOND PICTURE */
            if (sond_picture) {

                formData.append('attachment_1', sond_picture?.file)
                formData.append('attachment_type_1', sond_picture?.type)
                formData.append('attachment_mime_type_1', sond_picture?.mime_type)

                if (sond_picture.type === 'image') {
                    formData.append('attachment_width_1', sond_picture?.width)
                    formData.append('attachment_height_1', sond_picture?.height)
                }

                if (sond_picture.type === 'video') {

                    formData.append('attachment_thumbnail_1', sond_thumbnail)

                    formData.append('attachment_width_1', sond_picture?.width)
                    formData.append('attachment_height_1', sond_picture?.height)
                    formData.append('attachment_duration_1', sond_picture?.duration)
                    formData.append('attachment_size_1', sond_picture?.size)
                    formData.append('attachment_extension_1', sond_picture?.extension)
                }

            }

            // ATTACHEMENT
            if (attachments?.length !== 0) {

                attachments.forEach((attachment, index) => {
                    console.log('attachment index:', index + 2)
                    formData.append(`attachment_${index + 2}`, attachment?.file)
                    formData.append(`attachment_type_${index + 2}`, attachment?.type)
                    formData.append(`attachment_mime_type_${index + 2}`, attachment?.mime_type)

                    if (attachment?.type === 'image') {
                        formData.append(`attachment_width_${index + 2}`, attachment?.width)
                        formData.append(`attachment_height_${index + 2}`, attachment?.height)
                    }

                    if (attachment?.type === 'video') {

                        formData.append(`attachment_thumbnail_${index + 2}`, attachmentThumbnail[index])

                        formData.append(`attachment_width_${index + 2}`, attachment?.width)
                        formData.append(`attachment_height_${index + 2}`, attachment?.height)
                        formData.append(`attachment_duration_${index + 2}`, attachment?.duration)
                        formData.append(`attachment_size_${index + 2}`, attachment?.size)
                        formData.append(`attachment_extension_${index + 2}`, attachment?.extension)
                    }

                });
            }


            setError('')
            setEventErrors({})
            setIsLoading(true)
            setStateCreation(true)
            const response = await axios.post(`${Constants.API_URL}/api/events`, formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    let pourcentage = Math.round((loaded / total) * 100)
                    setUploadProgress(pourcentage)
                    console.log(Math.round((loaded / total) * 100), '% publication en cours...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            console.log('Create event response:', response.data)
            if (response.status === 200) {
                if (response.data.success) {
                    setSond_title('')
                    setSond_place('')
                    setSond_date('')
                    setSond_time('')
                    setEvent_type('PUBLIC')
                    setSond_caption('')
                    goBack()
                    navigate('home', { options: { replace: true } })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                } else {
                    setError('Cette opinion a déjà été publier.')
                }
            }
            setIsLoading(false)
            setStateCreation(false)
        } catch (response) {
            setStateCreation(false)
            setIsLoading(false)
            console.log('Create event error:', response?.response?.data || error);
        }
    }




    //============================[[ EDIT EVENT ]]=============================//
    const handleEditEvent = async () => {
        try {
            // CHECK FORM
            if (!sond_title?.trim()) {
                setEventErrors({ title: t('enter_the_event_title') })
                return
            }
            if (!sond_caption?.trim()) {
                setEventErrors({})
                setError(t('enter_the_event_description'))
                return
            }
            if (!sond_place?.trim()) {
                setEventErrors({ location: t('enter_the_location_Of_the_event') })
                return
            }
            if (!sond_date) {
                setEventErrors({ date: t('enter_the_date_of_the_event') })
                return
            }
            if (!sond_time) {
                setEventErrors({ date: t('enter_the_time_of_the_event') })
                return
            }
            setEventErrors({})
            setError('')
            console.log('Edit new event...', sond_date)

            const formData = new FormData();

            formData.append('_method', 'put');
            formData.append('event_title', sond_title)
            formData.append('event_descr', sond_caption)
            formData.append('event_localisation', sond_place)
            formData.append('event_date', sond_date)
            formData.append('event_time', sond_time)
            formData.append('event_type', event_type)

            formData.append('sond_group', sond_group)
            formData.append('sond_diffusion', sond_diffusion) // 0 public or 1 private
            formData.append('sond_device_os', 'Web')

            if (authData?.user?.user_gold) {
                formData.append('sond_validity', sond_validity)
            }

            if (sond_audio) {
                formData.append('attachment_1', sond_audio)
                formData.append('attachment_type_1', 'audio')
            }

            /* SOND PICTURE */
            // if (sond_picture) {

            //     formData.append('attachment_1', sond_picture?.file)
            //     formData.append('attachment_type_1', sond_picture?.type)
            //     formData.append('attachment_mime_type_1', sond_picture?.mime_type)

            //     if (sond_picture.type === 'image') {
            //         formData.append('attachment_width_1', sond_picture?.width)
            //         formData.append('attachment_height_1', sond_picture?.height)
            //     }

            //     if (sond_picture.type === 'video') {

            //         formData.append('attachment_thumbnail_1', sond_thumbnail)

            //         formData.append('attachment_width_1', sond_picture?.width)
            //         formData.append('attachment_height_1', sond_picture?.height)
            //         formData.append('attachment_duration_1', sond_picture?.duration)
            //         formData.append('attachment_size_1', sond_picture?.size)
            //         formData.append('attachment_extension_1', sond_picture?.extension)
            //     }

            // }

            // ATTACHEMENT
            // if (attachments?.length !== 0) {

            //     attachments.forEach((attachment, index) => {
            //         console.log('attachment index:', index + 2)
            //         formData.append(`attachment_${index + 2}`, attachment?.file)
            //         formData.append(`attachment_type_${index + 2}`, attachment?.type)
            //         formData.append(`attachment_mime_type_${index + 2}`, attachment?.mime_type)

            //         if (attachment?.type === 'image') {
            //             formData.append(`attachment_width_${index + 2}`, attachment?.width)
            //             formData.append(`attachment_height_${index + 2}`, attachment?.height)
            //         }

            //         if (attachment?.type === 'video') {

            //             formData.append(`attachment_thumbnail_${index + 2}`, attachmentThumbnail[index])

            //             formData.append(`attachment_width_${index + 2}`, attachment?.width)
            //             formData.append(`attachment_height_${index + 2}`, attachment?.height)
            //             formData.append(`attachment_duration_${index + 2}`, attachment?.duration)
            //             formData.append(`attachment_size_${index + 2}`, attachment?.size)
            //             formData.append(`attachment_extension_${index + 2}`, attachment?.extension)
            //         }

            //     });
            // }


            setError('')
            setEventErrors({})
            setIsLoading(true)
            setStateCreation(true)
            const response = await axios.post(`${Constants.API_URL}/api/events/${event_id}`, formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    let pourcentage = Math.round((loaded / total) * 100)
                    setUploadProgress(pourcentage)
                    console.log(Math.round((loaded / total) * 100), '% publication en cours...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            console.log('Edit event response:', response.data)
            if (response.status === 200) {
                if (response.data.success) {
                    setSond_title('')
                    setSond_place('')
                    setSond_date('')
                    setSond_time('')
                    setEvent_type('PUBLIC')
                    setSond_caption('')
                    goBack()
                    navigate('home', { options: { replace: true } })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                } else {
                    setError('Cette opinion a déjà été publier.')
                }
            }
            setIsLoading(false)
            setStateCreation(false)
        } catch (response) {
            setStateCreation(false)
            setIsLoading(false)
            console.log('Edit event error:', response?.response?.data || error);
        }
    }







    const renderOption = () => {
        return (
            <div style={{ marginBottom: '20px' }}>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>

                    <span style={{ fontWeight: '600', fontSize: '14px', marginRight: '20px' }}>
                        {t('privacy')}
                    </span>
                    <Select
                        defaultValue={diffusion_options.filter(item => item.value == sond_diffusion)}
                        isSearchable={false}
                        onChange={newValue => setSond_diffusion(newValue.value)}
                        options={diffusion_options}
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                borderColor: '#9e9e9e',
                                minHeight: '30px',
                                height: '30px',
                                width: '100%',
                                boxShadow: state.isFocused ? null : null,
                            }),

                            valueContainer: (provided, state) => ({
                                ...provided,
                                height: '30px',
                                padding: '0 6px'
                            }),

                            input: (provided, state) => ({
                                ...provided,
                                margin: '0px',
                            }),
                            indicatorSeparator: state => ({
                                display: 'none',
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                height: '30px',
                            }),

                        }}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>

                    {sond_id === 0 &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '30px' }}>
                            <span style={{ fontWeight: '600', fontSize: '14px', marginRight: '20px' }}>
                                {t('event')}
                            </span>
                            <Switch
                                checked={eventIsActive}
                                onChange={checked => setEventIsActive(checked)}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor={color.primary}
                                handleDiameter={20}
                                height={25}
                            />
                        </div>
                    }

                    {(sond_id === 0 && eventIsActive) &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '30px' }}>
                            <span style={{ fontWeight: '600', fontSize: '14px', marginRight: '20px' }}>
                                {t('public_event')}
                            </span>
                            <Switch
                                checked={event_type === 'PUBLIC' ? true : false}
                                onChange={checked => handleChangeEventType(checked)}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor={color.primary}
                                handleDiameter={20}
                                height={25}
                            />
                        </div>
                    }

                    {(sond_id === 0 && !eventIsActive) &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ fontWeight: '600', fontSize: '14px', marginRight: '20px' }}>
                                {t('poll')}
                            </span>
                            <Switch
                                checked={choiceIsActive}
                                onChange={checked => setChoiceIsActive(checked)}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor={color.primary}
                                handleDiameter={20}
                                height={25}
                            />
                        </div>
                    }


                </div >



            </div>

        )
    }


    return (
        <Modal scrollable={true} show={show}>
            <div className='create-opinion'>

                <div className='create-opinion-header'>
                    <h3 style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', width: '100%', marginLeft: '10%', marginTop: '5px' }}>
                        {t('create_opinion')}
                    </h3>
                    <button onClick={goBack} style={{ marginRight: '5px' }} >
                        <IoCloseSharp size={32} />
                    </button>
                </div>

                <div className='create-opinion-content'>

                    <div className='box-user'>
                        <ProfileCard
                            user={authData?.user}
                        />
                        {ago_id !== 0 &&
                            <b style={{ fontSize: '13px', backgroundColor: color.primary, color: color.white, marginBottom: '10px', borderRadius: '20px', paddingRight: '10px', paddingBottom: '2px', paddingLeft: '10px' }}>
                                {ago_title}
                            </b>
                        }
                    </div>

                    {renderOption()}

                    <span style={{ width: '100%', fontSize: '13px', textAlign: 'center', color: color.danger, fontWeight: '500' }}>
                        {error}
                    </span>

                    {stateCreation &&
                        <h6 style={{ textAlign: 'center', color: color.primary, fontWeight: '500', fontSize: '14px' }}>
                            {uploadProgress}% {t('publishing_in_progress')}
                        </h6>
                    }

                    <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>

                        {eventIsActive &&
                            <div style={{ marginBottom: '20px' }}>
                                <TextInput
                                    placeholder={t('event_title')}
                                    value={sond_title}
                                    setValue={setSond_title}
                                    type='text'
                                    style={{
                                        width: '100%',
                                    }}
                                />
                                <span style={{ fontSize: '13px', textAlign: 'center', color: color.danger, fontWeight: '500', marginTop: '3px' }}>
                                    {eventErrors?.title}
                                </span>
                            </div>
                        }

                        <MentionsInput
                            placeholder={eventIsActive ? `Event description` : t('enter_your_opinion')}
                            value={sond_caption}
                            onChange={event => setSond_caption(event?.target?.value)}
                            className='mention-input'>

                            <Mention
                                trigger="@"
                                data={buddies}
                                renderSuggestion={renderSuggestion}
                                displayTransform={(id, display) => {
                                    return `@${display}`
                                }}
                            />

                        </MentionsInput>


                        {eventIsActive &&
                            <div style={{ marginBottom: '20px' }}>

                                <div style={{ width: '100%', marginBottom: '20px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: '600' }}>
                                        Location of the event
                                    </span>
                                    <TextInput
                                        placeholder={`Location of the event`}
                                        value={sond_place}
                                        setValue={setSond_place}
                                        type='text'
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    <span style={{ fontSize: '13px', textAlign: 'center', color: color.danger, fontWeight: '500', marginTop: '3px' }}>
                                        {eventErrors?.location}
                                    </span>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ width: '48%', }}>
                                        <span style={{ fontSize: '14px', fontWeight: '600' }}>
                                            Date of the event {sond_date}
                                        </span>
                                        <TextInput
                                            placeholder={` Date of the event`}
                                            value={sond_date}
                                            setValue={setSond_date}
                                            type='date'
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </div>

                                    <div style={{ width: '48%', }}>
                                        <span style={{ fontSize: '14px', fontWeight: '600' }}>
                                            Time of the event
                                        </span>
                                        <TextInput
                                            placeholder={`Time of the event`}
                                            value={sond_time}
                                            setValue={setSond_time}
                                            type='time'
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </div>

                                </div>
                                <span style={{ fontSize: '13px', textAlign: 'center', color: color.danger, fontWeight: '500', marginTop: '3px' }}>
                                    {eventErrors?.date}
                                </span>
                            </div>
                        }

                        {sond_id === 0 &&
                            <div className='box-attachment'>

                                <h6 style={{ marginTop: '5px' }}>
                                    {t('add_to_your_opinion')}
                                </h6>

                                <div>
                                    <label onChange={(e) => handleFileChange(e, 'attachment')} htmlFor="formId" style={{ marginRight: '20px' }}>
                                        <input accept="image/*, video/*" name="" type="file" id="formId" hidden />
                                        <FaPhotoVideo size={32} color={color.success} />
                                    </label>

                                    <FaRegFileAudio size={30} color={color.info} onClick={() => setShowAlert(true)} style={{ marginRight: '20px' }} />
                                    <MdOutlineEmojiEmotions size={32} color='#ffe400' onClick={() => setShowAlert(true)} style={{ marginRight: '20px' }} />
                                </div>
                            </div>
                        }

                        {previewMedia?.length != 0 &&
                            previewMedia.map((item, index) => {
                                return (
                                    <img key={index} src={item?.uri} width={100} alt='' style={{ marginRight: '15px', marginBottom: '10px', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                )
                            })
                        }


                        {/* RENDER OPTION */}
                        {choiceIsActive &&
                            <div>

                                <h6 style={{ fontSize: '13px', textAlign: 'center', color: 'red', fontWeight: '500', marginTop: '10px' }}>
                                    {errorOption}
                                </h6>

                                {/* OPTION 1 */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>

                                    <TextInput
                                        placeholder={`${t('choice')} 1 ${t('mandatory')}`}
                                        value={opt_libelle1}
                                        setValue={setOpt_libelle1}
                                        type='text'
                                        style={{
                                            width: '70%',
                                        }}
                                    />

                                    {previewOption1 &&
                                        <img src={previewOption1} width={45} height={45} alt='' style={{ objectFit: 'cover', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                    }

                                    <label
                                        htmlFor="choice1"
                                        onChange={(e) => handleFileChange(e, 'choice1')}
                                        style={{
                                            border: `1px ${color.danger} solid`,
                                            borderRadius: '3px',
                                            padding: '7px'
                                        }}>
                                        <input accept="image/*, video/*" name="" type="file" id="choice1" hidden />
                                        <FaPhotoVideo size={28} color={color.danger} />
                                    </label>

                                </div>



                                {/* OPTION 2 */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>

                                    <TextInput
                                        placeholder={`${t('choice')} 2 ${t('mandatory')}`}
                                        value={opt_libelle2}
                                        setValue={setOpt_libelle2}
                                        type='text'
                                        style={{
                                            width: '70%',
                                        }}
                                    />

                                    {previewOption2 &&
                                        <img src={previewOption2} width={45} height={45} alt='' style={{ objectFit: 'cover', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                    }

                                    <label
                                        htmlFor="choice2"
                                        onChange={(e) => handleFileChange(e, 'choice2')}
                                        style={{
                                            border: `1px ${color.danger} solid`,
                                            borderRadius: '3px',
                                            padding: '7px'
                                        }}>
                                        <input accept="image/*, video/*" name="" type="file" id="choice2" hidden />
                                        <FaPhotoVideo size={28} color={color.danger} />
                                    </label>

                                </div>

                                {/* OPTION 3 */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>

                                    <TextInput
                                        placeholder={`${t('choice')} 3`}
                                        value={opt_libelle3}
                                        setValue={setOpt_libelle3}
                                        type='text'
                                        style={{
                                            width: '70%',
                                        }}
                                    />

                                    {previewOption3 &&
                                        <img src={previewOption3} width={45} height={45} alt='' style={{ objectFit: 'cover', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                    }

                                    <label
                                        htmlFor="choice3"
                                        onChange={(e) => handleFileChange(e, 'choice3')}
                                        style={{
                                            border: `1px ${color.danger} solid`,
                                            borderRadius: '3px',
                                            padding: '7px'
                                        }}>
                                        <input accept="image/*" name="" type="file" id="choice3" hidden />
                                        <FaPhotoVideo size={28} color={color.danger} />
                                    </label>

                                </div>

                                {/* OPTION 4 */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>

                                    <TextInput
                                        placeholder={`${t('choice')} 4`}
                                        value={opt_libelle4}
                                        setValue={setOpt_libelle4}
                                        type='text'
                                        style={{
                                            width: '70%',
                                        }}
                                    />

                                    {previewOption4 &&
                                        <img src={previewOption4} width={45} height={45} alt='' style={{ objectFit: 'cover', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                    }

                                    <label
                                        htmlFor="choice4"
                                        onChange={(e) => handleFileChange(e, 'choice4')}
                                        style={{
                                            border: `1px ${color.danger} solid`,
                                            borderRadius: '3px',
                                            padding: '7px'
                                        }}>
                                        <input accept="image/*" name="" type="file" id="choice4" hidden />
                                        <FaPhotoVideo size={28} color={color.danger} />
                                    </label>

                                </div>

                                {/* OPTION 5 */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>

                                    <TextInput
                                        placeholder={`${t('choice')} 5`}
                                        value={opt_libelle5}
                                        setValue={setOpt_libelle5}
                                        type='text'
                                        style={{
                                            width: '70%',
                                        }}
                                    />

                                    {previewOption5 &&
                                        <img src={previewOption5} width={45} height={45} alt='' style={{ objectFit: 'cover', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                    }

                                    <label
                                        htmlFor="choice5"
                                        onChange={(e) => handleFileChange(e, 'choice5')}
                                        style={{
                                            border: `1px ${color.danger} solid`,
                                            borderRadius: '3px',
                                            padding: '7px'
                                        }}>
                                        <input accept="image/*" name="" type="file" id="choice5" hidden />
                                        <FaPhotoVideo size={28} color={color.danger} />
                                    </label>

                                </div>

                                {/* OPTION 6 */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3%' }}>

                                    <TextInput
                                        placeholder={`${t('choice')} 6`}
                                        value={opt_libelle6}
                                        setValue={setOpt_libelle6}
                                        type='text'
                                        style={{
                                            width: '70%',
                                        }}
                                    />

                                    {previewOption6 &&
                                        <img src={previewOption6} width={45} height={45} alt='' style={{ objectFit: 'cover', border: '1px #f1f1f1 solid', borderRadius: '3px' }} />
                                    }

                                    <label
                                        htmlFor="choice6"
                                        onChange={(e) => handleFileChange(e, 'choice6')}
                                        style={{
                                            border: `1px ${color.danger} solid`,
                                            borderRadius: '3px',
                                            padding: '7px'
                                        }}>
                                        <input accept="image/*" name="" type="file" id="choice6" hidden />
                                        <FaPhotoVideo size={28} color={color.danger} />
                                    </label>

                                </div>

                            </div>
                        }

                        <ButtonSubmit
                            value={sond_id === 0 ? t('publish') : t('edit')}
                            isLoading={isLoading}
                            loadBoostrapColor={'light'}
                            style={{
                                width: '100%',
                                color: 'white',
                                marginBottom: '5%',
                                backgroundColor: color.primary
                            }}
                        />

                    </form>

                </div>


            </div>

            <ModelAlert
                message={t('coming_soon')}
                show={showAlert}
                setShow={setShowAlert}
            />

            <ModelAlert
                message={t('video_too_large_please_select_a_video_less_than_50mb')}
                show={showAlertVideo}
                setShow={setShowAlertVideo}
            />

        </Modal>
    )
}

export default ModalCreateOpinion